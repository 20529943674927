var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"server-items-length":_vm.total,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.dean",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"100px"}},[_vm._v(" "+_vm._s(item.topicAbsence.dean.surname)+" "+_vm._s(item.topicAbsence.dean.name.charAt(0))+". "+_vm._s(item.topicAbsence.dean.patronymic.charAt(0))+". ")])]}},{key:"item.student",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"100px"}},[_vm._v(" "+_vm._s(item.student.surname)+" "+_vm._s(item.student.name.charAt(0))+". "+_vm._s(item.student.patronymic.charAt(0))+". ")])]}},{key:"item.studentInfo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"100px"}},[_c('div',[_vm._v(_vm._s(item.student.speciality.nameShortUk))]),_c('div',[_vm._v(_vm._s(item.student.semester.yearNumber)+" курс")]),_c('div',[_vm._v(_vm._s(item.student.academicGroup.groupNumber)+" група")])])]}},{key:"item.payable",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(props.item.isSubjectPayable)?_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("attach_money")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Оплачувані допуски")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!props.item.isSubjectPayable)?_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("strikethrough_s")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("НЕоплачувані допуски")])])]}},{key:"item.reworkPrice",fn:function(props){return [_vm._v(" "+_vm._s(props.item.reworkPrice)+" ")]}},{key:"item.subject",fn:function(props){return [_vm._v(" "+_vm._s(props.item.subject.nameUk)+" ")]}},{key:"item.topic",fn:function(props){return [_vm._v(" "+_vm._s(((props.item.topic.orderNumber) + ". " + (props.item.topic.nameUk)))+" ")]}},{key:"item.academicHours",fn:function(props){return [_vm._v(" "+_vm._s(props.item.topic.academicHours)+" ")]}},{key:"item.passDate",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatShortDate(props.item.classDate))+" ")]}},{key:"item.emitDate",fn:function(props){return [_vm._v(" "+_vm._s(props.item.topicAbsence != null ? _vm.formatLongDate(props.item.topicAbsence.admissionTime) : "-")+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.topicAbsence.number)+" ")]}},{key:"item.pass",fn:function(props){return [(props.item.topicAbsence != null)?_c('admission-details',{attrs:{"topicAbsence":props.item.topicAbsence}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }