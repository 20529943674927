var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.pagination,"footer-props":{
            itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.finalControlType",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.mapFinalControlType(item.finalControlType)))]}},{key:"item.canBlockModules",fn:function(ref){
        var item = ref.item;
return [(!item.canBlockModules)?_c('v-icon',[_vm._v("radio_button_unchecked")]):_vm._e(),(item.canBlockModules)?_c('v-icon',[_vm._v("radio_button_checked")]):_vm._e()]}},{key:"item.canBlockTopics",fn:function(ref){
        var item = ref.item;
return [(!item.canBlockTopics)?_c('v-icon',[_vm._v("radio_button_unchecked")]):_vm._e(),(item.canBlockTopics)?_c('v-icon',[_vm._v("radio_button_checked")]):_vm._e()]}},{key:"item.departments",fn:function(ref){
        var item = ref.item;
return _vm._l((item.departments),function(department,index){return _c('div',{key:index},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-chip',_vm._g({},on),[_vm._v(_vm._s(department.nameShortUk))])]}}],null,true)},[_vm._v(" "+_vm._s(department.nameUk)+" ")])],1)})}},{key:"item.specialities",fn:function(ref){
        var item = ref.item;
return _vm._l((item.specialities),function(speciality,index){return _c('div',{key:index},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-chip',_vm._g({},on),[_vm._v(_vm._s(speciality.nameShortUk))])]}}],null,true)},[_vm._v(" "+_vm._s(speciality.nameUk)+" ")])],1)})}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"actions-wrapper"},[_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editItem', item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deleteItem', item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }