const StudentRootView = () => import(/* webpackChunkName: "student" */ '../views/student/StudentRootView');
const AbsentsView = () => import(/* webpackChunkName: "student" */ "../views/student/AbsentsView");
const MarksView = () => import(/* webpackChunkName: "student" */ "../views/student/MarksView");
const MarksCalendarView = () => import(/* webpackChunkName: "student" */ "../views/student/MarksCalendarView");
const PaymentHistoryView = () => import(/* webpackChunkName: "student" */ "../views/student/PaymentHistoryView");
const StudentSuperAdminPreSelect = () => import(/* webpackChunkName: "student" */ "../views/student/StudentSuperAdminPreSelect");
const ConsultationsAndReworks = () => import(/* webpackChunkName: "student" */ "../views/student/ConsultationsAndReworks");
const MarksByMonthView = () => import(/* webpackChunkName: "student" */ "../views/student/MarksByMonthView");

import routeGuardStudent from "../routeGuards/routeGuardStudent";

export default (router) => {
 return [
     {
         path: '/student',
         component: StudentRootView,
         name: 'studentRoot',
         beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
         redirect: {name: 'studentSuperAdminPreselect'},
         children: [
             {
                 path: 'student-sa-preselect',
                 name: "studentSuperAdminPreselect",
                 component: StudentSuperAdminPreSelect,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
             },
             {
                 path: 'marks/:subject?',
                 name: 'studentMarks',
                 component: MarksView,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
             },             
             {
                 path: 'marksCalendar',
                 name: 'studentMarksCalendar',
                 component: MarksCalendarView,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
             },
             {
                 path: 'marksByMonth',
                 name: 'studentMarksByMonth',
                 component: MarksByMonthView,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
             },
             {
                 path: 'absents',
                 name: 'absents',
                 component: AbsentsView,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),

             },
             {
                 path: 'consultationsAndReworks/:subject?',
                 name: 'consultationsAndReworks',
                 component: ConsultationsAndReworks,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),

             },
             {
                 path: 'paymentHistory',
                 name: 'paymentHistory',
                 component: PaymentHistoryView,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
             },
         ],
     },
 ];
}