<template>
    <v-container class="px-0" grid-list-md style="position: relative;">
        <block-loader v-if="loadingSave"/>
        <v-layout v-if="loading" align-center justify-center>
            <v-progress-circular
                    :size="150"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </v-layout>
        <v-layout v-if="!loading && !error" row wrap >
            <v-flex xs12 class="center-table-wrapper">
                    <table class="modules-table elevation-3">
                        <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>ПІП студента</th>

                            <th style="max-width: 80px"  class="mark-input-control-cell-header">
                                Середній бал
                            </th>
                            <th style="max-width: 80px"  class="mark-input-control-cell-header">
                                Поточний бал
                            </th>
                            <th class="mark-input-control-cell-header">
                                ІСРС
                            </th>
                            <th class="mark-input-control-cell-header">
                                Результат МК
                            </th>
                            <th class="mark-input-control-cell-header">
                                Загальна оцінка
                            </th>
                            <th class="mark-input-control-cell-header">
                                Традиційна оцінка
                            </th>
                            <th class="mark-input-control-cell-header">
                                Статус
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(moduleView, index) in form.moduleViews.modules">
                            <td class="avatar-cell">
                                <v-avatar size="36px">
                                    <v-icon v-if="gendersEnum.male === moduleView.student.gender">person</v-icon>
                                    <v-icon v-if="gendersEnum.female === moduleView.student.gender">face</v-icon>
                                </v-avatar>
                            </td>
                            <td class="counter-cell input-mark">{{index + 1}}.</td>
                            <td class="student-name-module input-mark">{{moduleView.student.surname}}
                                {{moduleView.student.name}}
                            </td>

                            <td class="mark-input-control-cell text-center">{{moduleView.marksMean}}</td>
                            <td class="mark-input-control-cell text-center">{{moduleView.marksValueCurrent}}</td>
                            <td class="mark-input-control-cell text-center">{{moduleView.individualWork}}</td>
                            <td class="mark-input-control-cell text-center">
                                <div class="module-points">
                                    <v-text-field
                                            v-model.number="moduleView.moduleControlPoints"
                                            solo
                                            label="Бали"
                                            hide-details
                                            @keypress="isNumber($event, 2)"
                                            @input="debouncedUpdate($event, moduleView)"
                                            :disabled="moduleView.stateType != null"
                                            clearable
                                            :error-messages="getModuleError(index, 'moduleControlPoints')"
                                            class="mb-2"
                                    ></v-text-field>
                                </div>
                            </td>
                            <td class="mark-input-control-cell text-center">
                                <div class="title">{{moduleView.totalPoints}}</div>
                            </td>
                            <td class="mark-input-control-cell  text-center">
                                <v-chip v-if="moduleView.totalTraditional && !getIsError(moduleView)"
                                        text-color="white"
                                        :color="getValueTraditionalColor(moduleView.totalTraditional)">
                                    <span class="title">{{moduleView.totalTraditional}}</span>
                                </v-chip>
                                <div v-if="getIsError(moduleView)">
                                    <v-chip text-color="white" color="red">Помилка</v-chip>
                                </div>
                            </td>
                            <td class="mark-input-control-cell  text-center">
                                <div style="min-width: 210px">
                                    <v-select
                                            clearable
                                            :items="getModuleMarkStatuses(moduleView.stateType)"
                                            :disabled="isDisabledModuleStatusDropdown(moduleView.stateType) || 
                                                        moduleView.moduleControlPoints !== null"
                                            v-model="moduleView.stateType"
                                            item-text="nameUk"
                                            item-value="id"
                                            label="Виберіть статус"
                                            hide-details
                                            @change="onChangeStateType($event, moduleView)"
                                            :error-messages="getModuleError(index, 'stateType')"
                                    ></v-select>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
            </v-flex>
            <v-flex>
                <v-layout justify-center xs12>
                    <v-flex xs6>
                        <date-picker
                                v-model="form.classDate"
                                :max-date="new Date()"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-layout v-if="error" align-center justify-center>
            Виникла помилка при спробі додавання модульного контролю!
        </v-layout>
    </v-container>

</template>

<script>
    import HttpService from "../../../HttpService";
    import BlockLoader from "../../common/BlockLoader";
    import {isNumber} from "../../../helpers/numberHelper";
    import debounce from "lodash/debounce";
    import moduleMarkStatuses, {moduleMarkStatusesEnum} from "../../../constants/moduleMarkStatuses";
    import DatePicker from "../../common/DatePicker";
    import {gendersEnum} from "../../../constants/_internal/gendersEnum";
    import {finalControlTypesEnum} from "../../../constants/admin/finalControlTypes";

    export default {
        components: {
            BlockLoader,
            DatePicker
        },
        data: () => ({
            isNumber: isNumber,
            settings: {
                maxModuleControlPoints: 0,
                maxModulePoints: 0,
                minModuleControlPoints: 0,
                minModulePoints: 0,
                points3: 0,
                points4: 0,
                points5: 0,
            },
            form: {
                moduleViews: [],
                classDate: new Date(),
            },
            loading: false,
            moduleMarkStatuses: moduleMarkStatuses,
            error: false,
            gendersEnum,
        }),
        props: {
            activeModule: {
                type: Object,
                default: {}
            },
            errors: {
                type: Object,
                default: {}
            },
            loadingSave: {
                type: Boolean,
                default: false
            },
        },
        mounted() {
            this.loading = true;
            HttpService.getInstance()
                .get(`teacher/teacherModuleMarks/moduleInputView/${this.$route.params.group}/${this.activeModule.id}`)
                .then(resp => {
                    this.form.moduleViews = resp;
                }).then(() => {
                return HttpService.getInstance()
                    .get(`settings/ectsModule`);
            }).then(resp => {
                this.settings = resp;
                this.loading = false;
            }).catch((err) => {
                if (err.errorMessage) {
                    this.error = true;
                }
                this.loading = false;
            });
        },
        methods: {
            onChangeIndividualWorkType(value, moduleView) {
                if (value != null) {
                    moduleView.moduleControlPoints = null;
                }
            },
            isDisabledModuleStatusDropdown(stateType) {
                return (stateType === moduleMarkStatusesEnum.notCompleted) || (stateType === moduleMarkStatusesEnum.notAllowed);
            },
            getModuleMarkStatuses(stateType) {
                let moduleMarkStatusesFiltered = this.moduleMarkStatuses;
                if (this.activeModule.finalControlType === finalControlTypesEnum.finalModuleControl) {
                    moduleMarkStatusesFiltered = this.moduleMarkStatuses.filter(st => st.id !== moduleMarkStatusesEnum.passed);
                }
                
                if (moduleMarkStatusesEnum.notCompleted === stateType || moduleMarkStatusesEnum.notAllowed === stateType) {
                    return moduleMarkStatusesFiltered.filter(st => st.id === stateType);
                } else {
                    return moduleMarkStatusesFiltered.filter(st => st.id !== moduleMarkStatusesEnum.notCompleted &&
                        st.id !== moduleMarkStatusesEnum.notAllowed);
                }
            },
            getValueTraditionalColor(valueTraditional) {
                switch (valueTraditional) {
                    case 5:
                        return 'green accent-4';
                    case 4:
                        return 'light-blue darken-3';
                    case 3:
                        return 'orange darken-1';
                    case 2:
                        return 'red darken-1';
                    default:
                        return '';
                }
            },
            debouncedUpdate: debounce(function (event, moduleView) {
                this.updateTotalPoints(event, moduleView);
                this.updateTraditionalMark(moduleView);
            }, 200),
            getIsError(moduleView) {
                if (moduleView.moduleControlPoints !== null && 
                    moduleView.moduleControlPoints > this.settings.maxModuleControlPoints) {
                    return true;
                }
                return false;
            },
            updateTraditionalMark(moduleView) {
                moduleView.totalTraditional = null;
                if (moduleView.moduleControlPoints === null) {
                    return '';
                }
                
                if (moduleView.moduleControlPoints <= this.settings.maxModuleControlPoints &&
                    moduleView.moduleControlPoints >= this.settings.minModuleControlPoints) {

                    if (moduleView.totalPoints <= this.settings.maxModulePoints &&
                        moduleView.totalPoints >= this.settings.points5) {
                        moduleView.totalTraditional = 5;
                    }

                    else if (moduleView.totalPoints <= this.settings.points5 &&
                        moduleView.totalPoints >= this.settings.points4) {
                        moduleView.totalTraditional = 4;
                    }

                    else if (moduleView.totalPoints <= this.settings.points4 &&
                        moduleView.totalPoints >= this.settings.points3) {
                        moduleView.totalTraditional = 3;
                    }
                    else {
                        moduleView.totalTraditional = 2;
                    }
                }
                else if (moduleView.moduleControlPoints < this.settings.minModuleControlPoints) {
                    moduleView.totalTraditional = 2;
                }
            },
            updateTotalPoints(event, moduleView) {
                moduleView.totalPoints = null;
                if (!event) {
                    return;
                }

                const moduleControlPointsParsed = parseFloat(event);

                moduleView.totalPoints = moduleView.marksValueCurrent + moduleView.individualWork + moduleControlPointsParsed;
            },
            getModuleError(studentIndex, fieldName) {
                const moduleErrorRow = this.errors[`modules[${studentIndex}]`];
                if (moduleErrorRow) {
                    const moduleError = moduleErrorRow[fieldName];
                    if (moduleError) {
                        return moduleError;
                    }
                    
                    return "";
                }
                
                return "";
            },
            onChangeStateType(event, moduleView) {
                if (!event) {
                    return;
                }
                
                moduleView.stateType = event;
            }
        },
        watch: {
            form: {
                handler: function (newVal) {
                    this.$emit("change", newVal);
                },
                deep: true,
            }
        },
    }
</script>

<style lang="scss">

    .mark-btn {
        font-size: 20px !important;
    }

    .avatar-cell {
        padding: 0px 0px 0px 5px;
    }

    .counter-cell.input-mark {
        padding-left: 0px;
    }

    table.modules-table {
        & td.mark-input-control-cell {
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            padding-left: 8px;
            padding-right: 8px;
            max-width: 235px;

            &:last-child {
                border-right: none;
            }
        }
        
        & td.counter-cell {
            border-bottom: 1px solid #ccc;
        }

        & td.student-name-module {
            max-width: 170px;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;

            & .input-mark {
                padding: 0px 10px 0px 5px;
            }
        }

        & th.mark-input-control-cell-header {
            padding: 0 5px;
            max-width: 235px;
        }
    }

    .mark-topic-error {
        padding: 5px 10px;
        max-width: 230px;
        min-width: 200px;
    }

    .module-points {
        width: 120px;
        padding-top: 10px;

        & .v-text-field.v-text-field--enclosed .v-text-field__details {
            padding: 0;
        }
    }
</style>