var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"orange"}},[_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","dark":"","tabs":""},nativeOn:{"click":function($event){return _vm.$emit('shouldCloseAddEdit')}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[_vm._v("Пропуски та незараховані заняття для "+_vm._s(_vm.student.surname)+" "+_vm._s(_vm.student.name))]),_c('v-spacer')],1),_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs5":""}},[_c('date-picker',{attrs:{"label":"Дата заняття (від)"},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}})],1),_c('v-flex',{attrs:{"xs5":""}},[_c('date-picker',{attrs:{"label":"Дата заняття (до)"},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}})],1),_c('v-flex',{staticClass:"justify-end",attrs:{"xs2":""}},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.getItems()}}},[_vm._v("Застосувати")])],1)],1),_c('admission-types-legend'),_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"px-2",staticStyle:{"overflow-y":"auto","height":"calc(100vh - 280px)"}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.reworkItems,"server-items-length":_vm.reworkItems.length,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.attachment",fn:function(ref){
var item = ref.item;
return [(!_vm.isEmpty(item.attachmentFileName))?_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.onGetAttachment(item)}}},on),[_c('v-icon',[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v("Переглянути додаток (довідка, квитанція)")])])],1):_c('div',{staticClass:"text-center"},[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.couldRework)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.openReworkDialog(item)}}},on),[_c('v-icon',[_vm._v("done_outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Відпрацювати заняття")])]):_vm._e()]}},{key:"item.payable",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.isSubjectPayable)?_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v(" attach_money ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Оплачувані допуски")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!item.isSubjectPayable)?_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("strikethrough_s")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("НЕоплачувані допуски")])])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subject.nameUk)+" ")]}},{key:"item.topic",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.topic.orderNumber) + ". " + (item.topic.nameUk)))+" ")]}},{key:"item.classDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatShortDate(item.classDate))+" ")]}},{key:"item.mark",fn:function(ref){
var item = ref.item;
return [_c('mark-unsatisfactory-icons',{attrs:{"value":item.value}})]}},{key:"item.admission",fn:function(ref){
var item = ref.item;
return [(item.topicAbsence != null)?_c('admission-details',{attrs:{"topicAbsence":item.topicAbsence}}):_vm._e()]}}])})],1)])],1),_c('v-dialog',{attrs:{"max-width":"800","scrollable":""},model:{value:(_vm.confirmRework),callback:function ($$v) {_vm.confirmRework=$$v},expression:"confirmRework"}},[(_vm.confirmRework)?_c('rework-dialog',{attrs:{"student":_vm.student,"rework-item":_vm.selectedReworkItem},on:{"shouldCloseSaveConfirm":function($event){_vm.confirmRework = false},"saved":_vm.savedAdmission}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"1000px"},model:{value:(_vm.dialogUploadAttachmentView),callback:function ($$v) {_vm.dialogUploadAttachmentView=$$v},expression:"dialogUploadAttachmentView"}},[(_vm.dialogUploadAttachmentView)?_c('show-attachment',{attrs:{"item":_vm.dialogUploadItemView},on:{"shouldClose":function($event){_vm.dialogUploadAttachmentView = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }