<template>
    <v-card tile>
        <v-toolbar dark color="orange">
            <v-btn icon
                   dark
                   tabs
                   @click.native="$emit('shouldCloseAddEdit')"
                   class="ml-2"
            >
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Незараховані ПМК для {{student.surname}} {{student.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container grid-list-md style="height: 100%;">
            <v-layout wrap>
                <v-flex xs7>
                    <v-layout wrap>
                        <v-flex xs5 class="align-center">
                            <date-picker
                                    v-model="filter.dateFrom"
                                    label="Дата заняття (від)"
                            />
                        </v-flex>
                        <v-flex xs5>
                            <date-picker
                                    v-model="filter.dateTo"
                                    label="Дата заняття (до)"
                            />
                        </v-flex>
                        <v-flex xs2 class="justify-end">
                            <div class="d-flex align-center align-content-center">
                                <v-btn :loading="loading" color="primary" @click="getItems()">Застосувати</v-btn>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            
            <v-flex xs12>
                <v-data-table
                        :headers="headers"
                        :items="reworkModuleItems"
                        :loading="loading"
                        class="elevation-3"
                        hide-default-footer
                >
                    
                    <template v-slot:item.subject="{item}">
                        {{ item.subject.nameUk }}
                    </template>

                    <template v-slot:item.module="{item}">
                        {{ item.module.orderBy }}. {{ item.module.nameUk }}
                    </template>
                 
                    <template v-slot:item.classDate="{item}">
                        {{ formatShortDate(item.moduleControlMark.classDate) }}
                    </template>
                    
                    <template v-slot:item.currentPoints="{item}">
                        {{ item.moduleControlMark.marksValueCurrent }}
                    </template>        
                    
                    <template v-slot:item.moduleControlPoints="{item}">
                        {{ item.moduleControlMark.moduleControlPoints }}
                    </template>   
                    
                    <template v-slot:item.totalPoints="{item}">
                        {{ item.moduleControlMark.totalPoints }}
                    </template>   
                    
                    <template v-slot:item.totalTraditional="{item}">
                        {{ item.moduleControlMark.totalTraditional }}
                    </template>
                    
                    <template v-slot:item.totalAbc="{item}">
                        {{ item.moduleControlMark.totalAbc }}
                    </template>

                    <template v-slot:item.actions="{item}">
                        <v-tooltip
                                v-if="item.couldReworkModule"
                                bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn @click="openReworkDialog(item)" v-on="on" color="primary" fab small dark>
                                    <v-icon>done_outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Перескласти модульний контроль</span>
                        </v-tooltip>
                    </template>
                    
                </v-data-table>
            </v-flex>
        </v-container>

        <v-dialog v-model="confirmRework" max-width="1100" scrollable>
            <module-rework-dialog
                    v-if="confirmRework"
                    @shouldCloseSaveConfirm="confirmRework = false"
                    @saved="savedAdmission"
                    :student="student"
                    :rework-item="selectedReworkItem"
            >
            </module-rework-dialog>
        </v-dialog>
        
    </v-card>
</template>
<script>
    import moment from "moment";
    import HttpService from "../../../HttpService";
    import DatePicker from "../../../components/common/DatePicker";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import ModuleReworkDialog from "./ModuleReworkDialog";

    export default {
        components: {
            DatePicker,
            ModuleReworkDialog,
        },
        data: () => ({
            formatShortDate,
            loading: false,
            loadingSave: false,
            reworkModuleItems: [],

            confirmRework: false,
            selectedReworkItem: null,

            filter: {
                dateFrom: moment(new Date()).subtract(3, "months"),
                dateTo: new Date(),
            },
            headers: [
                {text: 'Дисципліна', value: 'subject', sortable: false},
                {text: 'Модуль', value: 'module', sortable: false},
                {text: 'Поточний бал ECTS', value: 'currentPoints', sortable: false},
                {text: 'Бали за модульний контроль', value: 'moduleControlPoints', sortable: false},
                {text: 'Загальний бал ECTS', value: 'totalPoints', sortable: false},
                {text: 'Оцінка ABC', value: 'totalAbc', sortable: false},
                {text: 'Традиційна оцінка', value: 'totalTraditional', sortable: false},
                {text: 'Дата проведення ПМК', value: 'classDate', sortable: false},
                {text: 'К-сть "2"', value: 'countUnsatisfactory', sortable: false},
                {text: 'К-сть "нб"', value: 'countAbsents', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false},
            ],
            confirmAdmission: false,
        }),
        props: {
            student: Object,
        },
        mounted() {
            this.getItems();
        },
        methods: {
            openReworkDialog(reworkItem) {
                this.confirmRework = true;
                this.selectedReworkItem = reworkItem;
            },
            getItems() {
                this.loading = true;

                const dateFromQuery = moment(this.filter.dateFrom).format("YYYY-MM-DD");
                const dateToQuery = moment(this.filter.dateTo).format("YYYY-MM-DD");

                HttpService.getInstance()
                    .get(`teacher/teacherReworks/getModuleReworksForStudent/${this.$route.params.department}/${this.$route.params.subject}/${this.student.id}?dateFrom=${dateFromQuery}&dateTo=${dateToQuery}`)
                    .then(resp => {
                        this.reworkModuleItems = resp;
                        this.loading = false;
                    });
            },
            giveAdmission(isMultiple, item) {
                if (!isMultiple) {
                    this.selectedAdmissions = [item];
                }
                else {
                    this.selectedAdmissions = this.selected;
                }
                
                this.confirmAdmission = true;
            },
            savedAdmission() {
                this.getItems();
                this.confirmRework = false;
            },
            prolongateAdmission(item) {
                this.prolongateAdmissionItem = item;
                this.prolongateAdmissionDialog = true;
            },
            getMarkAdmissionValidity(topicAbsence) {
                const admissionEnd = moment(topicAbsence.admissionEnd);
                
                return admissionEnd.diff(moment(new Date()), "minutes") > 0;
            }
        },
    }
</script>