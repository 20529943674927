<template>
    <div>
        <vue-headful title="eBSMU"/>
        <student-exam-results></student-exam-results>
    </div>
</template>

<script>
    import StudentExamResults from "../../components/finalExam/studentDashboard/StudentExamResults";

    export default {
        components: {
            StudentExamResults,
        },
        data: () => ({
        }),
        
    }
</script>