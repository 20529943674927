<template>
    <v-container fluid fill-height>
        <vue-headful
                title="eBSMU Дати іспитів"
        />
        <v-layout style="display: block;">
            <admin-exam-settings-dates-index></admin-exam-settings-dates-index>
        </v-layout>
    </v-container>
</template>
<script>
    import AdminExamSettingsDatesIndex
        from "../../components/finalExam/adminDashboard/settingsDates/AdminExamSettingsDatesIndex";

    export default  {
        components: {
            AdminExamSettingsDatesIndex,
        }
    }
</script>