var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"group-btns-wrapper"},[(_vm.selectedItemsComputed.length)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('change')}}},[_vm._v(" Поновити на вибраний факультет/спеціальність/курс ")]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.pagination,"show-select":"","footer-props":{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.surname",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.surname)+" "+_vm._s(item.name)+" "+_vm._s(item.patronymic))]}},{key:"item.birthday",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(_vm.formatShortDate(item.birthday)))]}},{key:"item.isExpelled",fn:function(ref){
            var item = ref.item;
return [_c('v-radio-group',{model:{value:(item.isExpelled),callback:function ($$v) {_vm.$set(item, "isExpelled", $$v)},expression:"item.isExpelled"}},[_c('v-radio',{attrs:{"value":true}})],1)]}},{key:"item.isAcademicVacation",fn:function(ref){
            var item = ref.item;
return [_c('v-radio-group',{model:{value:(item.isAcademicVacation),callback:function ($$v) {_vm.$set(item, "isAcademicVacation", $$v)},expression:"item.isAcademicVacation"}},[_c('v-radio',{attrs:{"value":true}})],1)]}},{key:"item.semester",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(((item.semester.yearNumber) + " (" + (item.semester.semesterNumber) + " сем.)")))]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deleteItem', item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1)]}}]),model:{value:(_vm.selectedItemsComputed),callback:function ($$v) {_vm.selectedItemsComputed=$$v},expression:"selectedItemsComputed"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }