<template>
    <form @submit.prevent="submitForm" novalidate="true" ref="addEditForm">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-textarea :error-messages="errors.nameUk"
                                          v-model="form.nameUk"
                                          label="Назва модулю (українською)"
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs12>
                            <v-textarea :error-messages="errors.nameEn"
                                          v-model="form.nameEn"
                                          label="Назва модулю (english)"
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.orderBy"
                                          v-model="form.orderBy"
                                          v-mask="['###']"
                                          label="Порядковий номер"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.topicsEvaluableCount"
                                          v-model="form.topicsEvaluableCount"
                                          v-mask="['###']"
                                          label="Кількість оцінюваних тем"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.topicsAllCount"
                                          v-model="form.topicsAllCount"
                                          v-mask="['###']"
                                          label="Загальна кількість тем"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                                <v-layout wrap>
                                    <v-flex xs6>
                                        <v-flex xs12 class="subtitle-1">Тип фінального оцінювання модуля:</v-flex>
                                        <v-flex xs12 class="caption">Тип фінального оцінювання модуля можливо змінити тільки глобально для всієї дисципліни</v-flex>
                                    </v-flex>

                                    <v-radio-group xs6 disabled v-model="form.finalControlType">
                                        <v-radio
                                                v-for="(finalControlType, index) in finalControlTypes"
                                                :key="index"
                                                :label="finalControlType.nameConst"
                                                :value="finalControlType.typeEnum"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-layout>
                        </v-flex>
                        <v-alert type="error" v-if="form.isValidationError">
                            Наявні помилки в балах ECTS для даного модуля. Внесіть види ІСРС або ж виправте бали ECTS. <br/>
                            Після внесення видів ІСРС Вам <strong>необхідно повернутися до редагування модулю</strong> та повторно зберегти його для усунення даної помилки.
                        </v-alert>
                        <v-flex xs12>
                            <v-switch
                                    label="Заблокувати від зміни іншою кафедрою"
                                    v-model="form.isBlocked"
                                    :error-messages="errors.isBlocked"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs4>
                            <v-text-field :error-messages="errors.ects5"
                                          v-model="form.ects5"
                                          @keypress="isNumber($event, 3)"
                                          label="Оцінка 5 ECTS"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs4>
                            <v-text-field :error-messages="errors.ects4"
                                          v-model="form.ects4"
                                          @keypress="isNumber($event, 3)"
                                          label="Оцінка 4 ECTS"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs4>
                            <v-text-field :error-messages="errors.ects3"
                                          v-model="form.ects3"
                                          @keypress="isNumber($event, 3)"
                                          label="Оцінка 3 ECTS"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-switch
                                    label="Наявні лекційні заняття у даному модулі"
                                    v-model="form.hasLectures"
                                    color="primary"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-switch
                                    label="Наявна індивідуальна самостійна робота студента"
                                    v-model="form.hasIndividualWork"
                                    :error-messages="errors.hasIndividualWork"
                                    color="primary"
                            ></v-switch>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
        <confirm-dialog 
                :loading="loadingSave" 
                v-model="confirmationModal" 
                @cancel="confirmationModal = false" 
                @confirm="confirmChange"
        />
    </form>
</template>

<script>
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import ConfirmDialog from "../../common/ConfirmDialog";
    import finalControlTypes from "../../../constants/admin/finalControlTypes";
    import { isNumber } from "../../../helpers/numberHelper";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
            subjectFinalControlType: Number,
            maxOrderBy: {
                type: Number,
                default: 0,
            },
        },
        components: {
            LoadingPlaceholder,
            ConfirmDialog
        },
        data: () => {
            return {
                isNumber: isNumber, 
                form: {
                    nameUk: null,
                    nameEn: null,
                    orderBy: null,
                    topicsEvaluableCount: null,
                    topicsAllCount: null,
                    finalControlType: 0,
                    isBlocked: false,
                    ects5: null,
                    ects4: null,
                    ects3: null,
                    hasIndividualWork: false,
                    hasLectures: false,
                },
                errors: {
                    nameUk: [],
                    nameEn: [],
                    orderBy: [],
                    topicsEvaluableCount: [],
                    topicsAllCount: [],
                    finalControlType: [],
                    isBlocked: [],
                    ects5: [],
                    ects4: [],
                    ects3: [],
                    hasIndividualWork: [],
                },
                loading: false,
                loadingSave: false,

                confirmationModal: false,
                finalControlTypes: finalControlTypes,
            }
        },
        mounted() {
            if (!this.editMode) {
                this.form.orderBy = this.maxOrderBy + 1;
            }
            
            
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`module/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        updated() {
            this.form.finalControlType = this.subjectFinalControlType;
        },
        methods: {
            mapFinalControlType(finalControlType) {
                return finalControlTypes.find(f => f.typeEnum === finalControlType).nameConst;
            },
            confirmChange() {
                this.confirmationModal = false;
                this.form.overwriteConfirm = true;
                this.submitForm();  
            },
            submitForm() {
                this.loadingSave = true;

                const additionalParams = {
                    department: {id: this.$route.params.department},
                    subject : {id: this.$route.params.subject}  
                };
                
                HttpService.getInstance()
                    .post("module", {...this.form, ...additionalParams})
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                        if (err.confirmation) {
                            this.confirmationModal = true;
                        }
                    });
            },
        }
    }
</script>