<template>
    <v-list dense>
        <template v-if="!loading && withNavigation" v-for="studNav in studNavigationItems">
            <v-subheader class="mt-4 grey--text text--darken-1 subtitle-1">{{studNav.name}}</v-subheader>
            <v-list-item v-if="studNav.specialities.length === 0">
                <span class="grey--text text--lighten-1"><i>Немає даних</i></span>
            </v-list-item>
            <navigation-list-template
                    @selectValue="setSelectedNavigationItem"
                    :items="studNav.specialities"
                    :withNavigation="withNavigation">
            </navigation-list-template>
        </template>

        <v-layout wrap v-if="(!withNavigation && value === null) || (value !== null && editMode)">
            <v-flex xs1>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="editMode && value !== null" @click="editMode = false" v-on="on">
                            <v-icon>remove_red_eye</v-icon>
                        </v-btn>
                    </template>
                    <span>Переглянути вибране значення</span>
                </v-tooltip>
            </v-flex>
            <v-flex xs11>
                <v-treeview
                        rounded
                        hoverable
                        activatable
                        dense
                        open-on-click
                        expand-icon="keyboard_arrow_down"
                        color="blue darken-4"
                        :items="studNavigationItems"
                        :return-object="true"
                        @update:active="onSelect($event)"
                ></v-treeview>
            </v-flex>
        </v-layout>
        
        <div v-if="!withNavigation && value !== null && selectedValueFormatted !== null && !editMode">
            <v-layout wrap>
                <v-flex xs1>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="editMode = true" v-on="on">
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Редагувати вибране значення</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn dark class="mt-2" color="red darken-1" @click="onDelete()" v-on="on">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Видалити вибране значення</span>
                    </v-tooltip>
                </v-flex>
                <v-flex xs11>
                    <div class="subtitle-1"><strong>Факультет:</strong> {{this.selectedValueFormatted.nameUk}}</div>
                    <div class="subtitle-1"><strong>Спеціальність:</strong> {{this.selectedValueFormatted.speciality.nameUk}}</div>
                    <div class="subtitle-1"><strong>Курс:</strong> {{this.selectedValueFormatted.speciality.semester.yearNumber}}</div>
                    <div v-if="groupLevel" class="subtitle-1"><strong>Група:</strong> {{this.selectedValueFormatted.speciality.semester.academicGroup.groupNumber}}</div>
                </v-flex>
            </v-layout>
        </div>
        
        <template v-if="loading">
            <v-container v-if="loading" fluid fill-height>
                <v-layout align-center justify-center>
                    <v-progress-circular
                            :size="150"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-layout>
            </v-container>
        </template>
        <template v-if="studNavigationItems.length === 0 && !loading">
            <v-list-item>
                <span class="grey--text text--lighten-1"><i>Немає груп</i></span>
            </v-list-item>
        </template>
    </v-list>
</template>
<script>
    import NavigationListTemplate from '../common/NavigationListTemplate';
    import HttpService from "../../HttpService";
    import DepartmentSubjectSwitcher from "../../components/common/DepartmentSubjectSwitcher";
    import {mapMutations} from "vuex";

    export default {
        components: {
            NavigationListTemplate,
            DepartmentSubjectSwitcher
        },
        mounted() {
            this.getMenuItems();
        },
        data: () => ({
            studNavigationItems: [],
            loading: false,
            isDepartmentSubjectSwitcherOpened: false,
            selectedValueFormatted: null,
            editMode: false,
        }),
        props: {
            value: {
              type: Object,
                default: () => {
                    return null;
                }
            },
            routeTo: {
                type: String,
                default: "registers"
            },
            additionalRouteParams: {
                type: Object,
                default: () => {
                }
            },
            apiEndpoint: {
                type: String,
            },
            withNavigation: {
                type: Boolean,
                default: true,
            },
            groupLevel: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            changeInputData() {
                const {studNavigationItems, value} = this;
                
                return {
                    studNavigationItems,
                    value,
                }
            }
        },
        watch: {
            changeInputData: {
                handler: function(newValue, oldValue) {
                    if (!newValue.value) {
                        return;
                    }
                    
                    if (!newValue.value.facultyId) {
                        return;
                    }

                    if (!newValue.value.specialityId) {
                        return;
                    }

                    if (!newValue.value.semesterId) {
                        return;
                    }

                    if (!newValue.value.academicGroupId) {
                        return;
                    }
                    
                    const studNavigationItemsClonned = [...newValue.studNavigationItems];
                    
                    if (newValue.value && studNavigationItemsClonned.length > 0) {
                        const facultyFound = studNavigationItemsClonned.find(s => s.id === newValue.value.facultyId);

                        facultyFound.speciality = facultyFound.children.find(spec => spec.id === newValue.value.specialityId);

                        facultyFound.speciality.semester = facultyFound.speciality.children.find(sem => sem.id === newValue.value.semesterId);

                        if (this.groupLevel) {
                            facultyFound.speciality.semester.academicGroup = facultyFound.speciality.semester.children.find(ac => ac.id === newValue.value.academicGroupId);
                        }
                        
                        this.selectedValueFormatted = facultyFound;
                    }

                },
                deep: true,
            },
        },
        methods: {
            onSelect($event) {
                this.editMode = false;
                this.$emit("input", $event[0]);
            },
            onDelete($event) {
                this.editMode = false;
                this.$emit("input", null);
            },
            setSelectedNavigationItem(item) {
                this.$emit("input", item);
            },
            isDepSubjNavigation() {
                return (this.$route.params.department !== undefined &&
                    this.$route.params.subject !== undefined);
            },
            ...mapMutations(['setSelectedNavigationItem']),
            getMenuItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(this.apiEndpoint)
                    .then(navItems => {
                        this.studNavigationItems = this.remapStudNavItems(navItems);
                        this.loading = false;
                    });

            },
            selectValue(value) {
                this.setSelectedNavigationItem(value);
            },
            remapStudNavItems(studNavigationItems) {
                if (studNavigationItems === undefined) {
                    return [];
                }

                let studNavigationItemsRemapped = studNavigationItems.slice(0);

                studNavigationItemsRemapped.map(fac => {
                    fac.name = fac.nameUk;
                    fac.type = "faculty";
                    
                    const remappedSpecs = fac.specialities.map(spec => {
                        spec.name = spec.nameUk;
                        spec.type = "speciality";

                        spec.children = spec.semesters.map(sem => {
                            sem.name = `${sem.yearNumber} курс (${sem.semesterNumber} семестр)`;
                            sem.type = "semester";

                            if (this.groupLevel) {
                                sem.children = sem.academicGroups.map(acgroup => {
                                    acgroup.name = `${acgroup.groupNumber} група`;
                                    acgroup.type = "academicGroup";
                                    
                                    acgroup.facultyId = acgroup.faculty.id;
                                    acgroup.specialityId = acgroup.speciality.id;
                                    acgroup.semesterId = acgroup.semester.id;
                                    acgroup.academicGroupId = acgroup.id;

                                    acgroup.route = {
                                        name: this.routeTo,
                                        params: {
                                            ...{
                                                group: acgroup.id,
                                                faculty: acgroup.faculty.id,
                                                speciality: acgroup.speciality.id,
                                                year: acgroup.semester.id,
                                            },
                                            ...this.additionalRouteParams
                                        }
                                    };

                                    if (this.$route.params.group &&
                                        acgroup.id.toString() === this.$route.params.group.toString()) {
                                        this.setSelectedNavigationItem(acgroup);
                                        acgroup.model = true;
                                    }

                                    return acgroup;
                                });
                            }

                            let someAcGroupActive = sem && sem.children && sem.children.find(ch => ch.model);

                            if (this.$route.params.year &&
                                sem.id.toString() === this.$route.params.year.toString() && someAcGroupActive !== undefined) {
                                sem.model = true;
                            }

                            delete sem.academicGroups;

                            if (sem.children) {
                                sem.children = sem.children.filter(ch => ch !== undefined);
                            }

                            if (this.groupLevel && sem.children && sem.children.length === 0) {
                                return undefined;
                            }

                            return sem;
                        });


                        let someSemesterActive = spec && spec.children.find(ch => ch && ch.model);

                        if (this.$route.params.speciality &&
                            spec.id.toString() === this.$route.params.speciality.toString() && someSemesterActive !== undefined) {
                            spec.model = true;
                        }

                        delete spec.semesters;
                        spec.children = spec.children.filter(ch => ch !== undefined);

                        if (spec.children.length === 0) {
                            return undefined;
                        }

                        return spec;
                    });
                    
                    fac.specialities = remappedSpecs;
                    fac.children = remappedSpecs;

                    if (fac.specialities.length === 0) {
                        return undefined;
                    }
                    
                    if (fac.children.length === 0) {
                        return undefined;
                    }

                    return fac;
                });

                return studNavigationItemsRemapped;
            },
        },
    }
</script>
<style lang="scss">
    .v-treeview-node__root {
        cursor: pointer;
    }
</style>