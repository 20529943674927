<template>
    <div>
        <v-container class="register-container">
            <v-layout justify-center>
                <div>
<!--                    <v-btn icon raised small color="white">-->
<!--                        <v-icon>chevron_left</v-icon>-->
<!--                    </v-btn>-->
<!--                    <v-btn raised small color="primary">1-10</v-btn>-->
<!--                    <v-btn raised small color="white">11-20</v-btn>-->
<!--                    <v-btn icon raised small color="white">-->
<!--                        <v-icon>chevron_right</v-icon>-->
<!--                    </v-btn>-->
                </div>
            </v-layout>
        </v-container>
        <v-container class="register-container">
            <v-layout justify-center>
                <v-flex xs12 class="scrollable">
<!--                    <table class="marks-table elevation-3 mb-6">-->
<!--                        <thead>-->
<!--                        <tr>-->
<!--                            <th class="counter-header" rowspan="2"/>-->
<!--                            <th class="student-name corner-left-top">-->
<!--                                Дата та тема заняття-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-date">-->
<!--                                <div class="lesson-date">-->
<!--                                    10.01.18-->
<!--                                </div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-date">-->
<!--                                <div class="lesson-date">-->
<!--                                    10.01.18-->
<!--                                </div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-date">-->
<!--                                <div class="lesson-date">-->
<!--                                    10.01.18-->
<!--                                </div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-date">-->
<!--                                <div class="lesson-date">-->
<!--                                    10.01.18-->
<!--                                </div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-date">-->
<!--                                <div class="lesson-date">-->
<!--                                    10.01.18-->
<!--                                </div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-date">-->
<!--                                <div class="lesson-date">-->
<!--                                    10.01.18-->
<!--                                </div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-date">-->
<!--                                <div class="lesson-date">-->
<!--                                    10.01.18-->
<!--                                </div>-->
<!--                            </th>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <th class="student-name corner-left-bottom">-->
<!--                                ПІП студента-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-number">-->
<!--                                <div class="lesson-number">1</div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-number">-->
<!--                                <div class="lesson-number">2</div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-number">-->
<!--                                <div class="lesson-number">3</div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-number">-->
<!--                                <div class="lesson-number">4</div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-number">-->
<!--                                <div class="lesson-number">5</div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-number">-->
<!--                                <div class="lesson-number">6</div>-->
<!--                            </th>-->
<!--                            <th class="cell-lesson-number">-->
<!--                                <div class="lesson-number">7</div>-->
<!--                            </th>-->
<!--                        </tr>-->
<!--                        </thead>-->
<!--                        <tbody>-->
<!--                        <tr>-->
<!--                            <td class="counter-cell">1. </td>-->
<!--                            <td class="student-name">Барбе Адріан</td>-->
<!--                            <td ripple class="mark mark5"><v-icon>done</v-icon></td>-->
<!--                            <td ripple class="mark mark5"><v-icon>done</v-icon></td>-->
<!--                            <td ripple class="mark mark5"><v-icon>done</v-icon></td>-->
<!--                            <td ripple class="mark mark5"><v-icon>done</v-icon></td>-->
<!--                            <td class="mark mark2"><v-icon>clear</v-icon></td>-->
<!--                            <td class="mark mark2"><v-icon>clear</v-icon></td>-->
<!--                            <td class="mark mark2"><v-icon>clear</v-icon></td>-->
<!--                        </tr>-->
<!--                        <tr v-for="ind in 13">-->
<!--                            <td class="counter-cell">{{ind}}. </td>-->
<!--                            <td class="student-name">Іванов Іван Іванович</td>-->
<!--                            <td class="mark mark2"><v-icon>clear</v-icon></td>-->
<!--                            <td class="mark mark2"><v-icon>clear</v-icon></td>-->
<!--                            <td class="mark mark5"><v-icon>done</v-icon></td>-->
<!--                            <td class="mark mark5"><v-icon>done</v-icon></td>-->
<!--                            <td class="mark mark5"><v-icon>done</v-icon></td>-->
<!--                            <td class="mark mark5"><v-icon>done</v-icon></td>-->
<!--                            <td class="mark mark5"><v-icon>done</v-icon></td>-->
<!--                        </tr>-->
<!--                        </tbody>-->
<!--                        <tfoot>-->
<!--                        <tr>-->
<!--                            <td></td>-->
<!--                            <td class="cell-professor-name corner-prof-bottom">-->
<!--                                ПІП викладача-->
<!--                            </td>-->
<!--                            <td class="cell-professor-name">ХВМ</td>-->
<!--                            <td class="cell-professor-name">МВФ</td>-->
<!--                            <td class="cell-professor-name">БАМ</td>-->
<!--                            <td class="cell-professor-name">КРТ</td>-->
<!--                            <td class="cell-professor-name">МПС</td>-->
<!--                            <td class="cell-professor-name">ЩАК</td>-->
<!--                            <td class="cell-professor-name">ПРУ</td>-->
<!--                        </tr>-->
<!--                        </tfoot>-->
<!--                    </table>-->
                    
                    <div>Функціонал буде впроваджено незабаром...</div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
    import AddMarkDialog from "./AddMarkDialog";
    
    export default {
        components: {
            AddMarkDialog,  
        },
        props: {
            activeModule: {
                type: Object
            }  
        },
        data() {
            return {
                fab: false,
                addMarkDialog: false, 
            }
        },
        mounted() {
            
        }
    }
</script>

<style lang="scss">
    .register-container {
        padding: 5px;
    }

    .lesson-date {
        font-weight: 200;
    }

    .student-name-wrapper {
        display: block;
        position: relative;
        width: 100%;
        height: 50px;
        font-weight: 200;
        color: #ccc;
    }

    .lesson-date-topic {
        text-align: right;
    }

    .student-name-text {
        position: absolute;
        bottom: 2px;
        left: 2px;
    }

    .cell-lesson-date {
        text-align: center;
        vertical-align: middle;
        padding: 10px 5px 10px 5px;
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        min-width: 25px;
    }

    .cell-lesson-number {
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
    }

    .corner-left-top {
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        font-weight: normal;
        font-size: 0.9em;
        text-align: right;
        color: #ccc;
    }

    .corner-left-bottom {
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        font-weight: normal;
        font-size: 0.9em;
        text-align: left;
        color: #ccc;
    }

    .counter-header,
    .counter-cell {
        width: 20px;
        text-align: right;
        padding-left: 10px;
    }

    .counter-header {
        border-bottom: 1px solid #ccc;
    }

    table.marks-table {
        border-collapse: collapse;
        margin: 0 auto;
        background: #fff;

        & tr {
            & td {
                border: 1px solid transparent;
                border-bottom: 1px solid #ccc;
            }
        }
    }
</style>