<template>
    <v-card>
        <v-card-title>
            <span class="headline">Додавання результату індивідуальної самостійної роботи студента</span>
        </v-card-title>
        <v-card-text>
            <individual-work-selector
                    :activeModule="activeModule" 
                    :loadingSave="loadingSave" 
                    :errors="errors"
                    @change="onChangeIndividualWork($event)"
            />
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    text
                    @click.native="$emit('shouldClose')"
                    :disabled="loading"
            >Відміна
            </v-btn>
            <v-btn
                    color="orange darken-1"
                    dark
                    type="button"
                    :disabled="loading"
                    @click="saveModule"
            >Внести результат ІСРС
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import IndividualWorkSelector from "./IndividualWorkSelector";
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";

    export default {
        props: {
            activeModule: {
                type: Object
            }
        },
        components: {
            LoadingPlaceholder,
            IndividualWorkSelector
        },
        data: () => {
            return {
                loading: false,
                individualWorkValues: {
                    individualWorkViews: []
                },

                loadingSave: false,
                errors: {},
            }
        },
        mounted() {
          this.errors = {};  
        },
        methods: {
            onChangeIndividualWork(value) {
                this.individualWorkValues = value;
            },
            saveModule() {
                this.$ga.event('professor', 'addIndividualWork', 'groupId', this.$route.params.group);

                this.loadingSave = true;
                HttpService.getInstance()
                    .post(`teacher/teacherIndividualWorkMarks/${this.activeModule.id}/${this.$route.params.group}`,
                        {
                            ...this.individualWorkValues.individualWorkViews,
                            ...{
                                professor: {id: 1},
                                department: {id: this.$route.params.department},
                                subject: {id: this.$route.params.subject},
                            }
                        }
                    )
                    .then(resp => {
                        this.loadingSave = false;
                        this.isSaved = true;
                        this.$emit('shouldSaveAndClose');
                    })
                    .catch(err => {                        
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            }
        }
    }
</script>