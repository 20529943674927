export const rolesEnum = {
    curator: "curator",
    student: "student",
    headDepartment: "headDepartment",
    professor: "professor",
    moderator: "moderator",
    inspector: "inspector",
    dean: "dean",
    rector: "rector",
    admin: "admin",
    edudept: "edudept",
    labstaff: "labstaff",
    finalExam: "finalExam",
    finalExamStudent: "finalExamStudent",
    finalExamAdmin: "finalExamAdmin",
    ratingist: "ratingist",
    ratingistChecker: "ratingistChecker",
};