var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"group-btns-wrapper"},[(_vm.selectedItemsComputed.length)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('change')}}},[_vm._v(" Змінити факультет/спеціальність/курс ")]):_vm._e(),(_vm.selectedItemsComputed.length)?_c('v-btn',{attrs:{"small":"","color":"red","dark":""},on:{"click":function($event){return _vm.$emit('deleteSelected')}}},[_vm._v(" Видалити ")]):_vm._e(),(_vm.selectedItemsComputed.length)?_c('v-btn',{attrs:{"small":"","color":"pink darken-1","dark":""},on:{"click":function($event){return _vm.$emit('expulseSelected')}}},[_vm._v(" Відрахувати ")]):_vm._e(),(_vm.selectedItemsComputed.length)?_c('v-btn',{attrs:{"small":"","color":"orange darken-3","dark":""},on:{"click":function($event){return _vm.$emit('academicVacationSelected')}}},[_vm._v(" Академічна відпустка ")]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-3",attrs:{"hide-default-footer":true,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"show-select":""},scopedSlots:_vm._u([{key:"item.surname",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.surname)+" "+_vm._s(item.name)+" "+_vm._s(item.patronymic))])]}},{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-xl-start"},[_vm._v(_vm._s(_vm.formatShortDate(item.birthday)))])]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-xs-left"},[(item.gender === 0)?_c('div',[_vm._v("Чоловіча")]):_vm._e(),(item.gender === 1)?_c('div',[_vm._v("Жіноча")]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editItem', item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deleteItem', item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1)]}}]),model:{value:(_vm.selectedItemsComputed),callback:function ($$v) {_vm.selectedItemsComputed=$$v},expression:"selectedItemsComputed"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }