<template>
    <div class="picture-input-wrapper d-flex align-center justify-center">
        <div v-if="isError && !loading" class="d-flex flex-column">
            <v-layout wrap class="justify-center text-center">
                <v-flex xs12>
                    <div class="text--darken-1 red--text mt-6">Виникла помилка</div>
                    <v-btn class="mt-2" @click="isError = false">Спробувати ще раз</v-btn>
                </v-flex>
            </v-layout>
        </div>
        <div v-if="loading">
            <v-layout align-center justify-center>
                <v-progress-circular
                        :size="150"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </v-layout>
        </div>
        <picture-input
                v-if="!isError && !loading"
                ref="pictureInput"
                @change="onFileUpload"
                @remove="onFileRemove"
                :zIndex="0"
                width="200"
                height="200"
                margin="16"
                accept="image/jpeg,image/png"
                size="10"
                buttonClass="v-btn v-btn--contained theme--light v-size--default primary upload-btn"
                removeButtonClass="v-btn v-btn--flat v-btn--text theme--light v-size--default primary--text upload-btn"
                :prefill="getImagePrefill"
                :removable="true"
                :customStrings="{
                  upload: '<p>Ваш прийстрій не підтримує завантаження файлів.</p>',
                  drag: 'Перетягніть зображення або <br>клікніть для вибору файлу',
                  tap: 'Торкніться тут, щоб вибрати фото <br>з вашої галереї',
                  change: 'Змінити зображення',
                  remove: 'Видалити зображення',
                  select: 'Вибрати зображення',
                  selected: '<p>Фото було успішно вибрано!</p>',
                  fileSize: 'Файл перевищує максимальний розмір',
                  fileType: 'Даний тип файлу не підтримується.',
                  aspect: 'Landscape/Portrait'
            }">
        </picture-input>
    </div>
</template>
<script>
    import PictureInput from 'vue-picture-input';
    import HttpService from "../../HttpService";
    import fileUploadEnum from "../../constants/_internal/fileUploadEnum";

    export default {
        components: {
            PictureInput,
        },
        props: {
            value: {
                type: String,
                default: null,
            },
            imageEndpoint: {
                type: String,
                default: null,
            },
            type: {
                type: String,
                default: fileUploadEnum.profileImages,
            }
        },
        data: () => ({
            isError: false,
            loading: false,

            fileName: null,
        }),
        computed: {
            getImagePrefill() {
                if (this.isError) {
                    return null;
                }
                if (this.imageEndpoint === null || this.value === null) {
                    return null;
                } else {
                    let imageEndpointSuffix = this.imageEndpoint;

                    switch (this.type) {
                        case fileUploadEnum.announcementImages:
                            imageEndpointSuffix = `${imageEndpointSuffix}/announcement`;
                            break;
                        case fileUploadEnum.profileImages:
                            imageEndpointSuffix = `${imageEndpointSuffix}/profile`;
                            break;
                        case fileUploadEnum.reworkAdmissions:
                            imageEndpointSuffix = `${imageEndpointSuffix}/reworkAdmission`;
                            break;
                        case fileUploadEnum.finalExamScreenShot:
                            imageEndpointSuffix = `${imageEndpointSuffix}/finalExamScreenshot`;
                            break;
                        default:
                            imageEndpointSuffix = "";
                    }
                    
                    return `${imageEndpointSuffix}/${this.value}`;
                }
            }
        },
        methods: {
            onFileUpload() {
                const fileToUpload = this.$refs.pictureInput.file;

                this.uploadFile(fileToUpload);
            },
            onFileRemove() {
                this.$emit('input', null);

                let uploadUrl = "";

                switch (this.type) {
                    case fileUploadEnum.announcementImages:
                        uploadUrl = "file/remove/announcement";
                        break;
                    case fileUploadEnum.profileImages:
                        uploadUrl = "file/remove/profile";
                        break;
                    case fileUploadEnum.reworkAdmissions:
                        uploadUrl = "file/remove/reworkAdmission";
                        break;
                    case fileUploadEnum.finalExamScreenShot:
                        uploadUrl = "file/remove/finalExamScreenshot";
                        break;
                    default:
                        uploadUrl = "";
                }

                let acessToken = localStorage.getItem('access_token');

                return HttpService.getInstance(
                    {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${acessToken}`
                    }
                )
                    .delete(`${uploadUrl}/${this.fileName}`)
                    .then(resp => {
                    });
            },
            uploadFile(file, name = "file") {
                let uploadUrl = "";

                switch (this.type) {
                    case fileUploadEnum.announcementImages:
                        uploadUrl = "file/upload/announcement";
                        break;
                    case fileUploadEnum.profileImages:
                        uploadUrl = "file/upload/profile";
                        break;
                    case fileUploadEnum.reworkAdmissions:
                        uploadUrl = "file/upload/reworkAdmission";
                        break;
                    case fileUploadEnum.finalExamScreenShot:
                        uploadUrl = "file/upload/finalExamScreenshot";
                        break;
                    default:
                        uploadUrl = "";
                }
                
                this.loading = true;
                const formData = new FormData();
                formData.append(name, file);

                let acessToken = localStorage.getItem('access_token');

                return HttpService.getInstance(
                    {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${acessToken}`
                    }
                )
                    .post(uploadUrl, formData)
                    .then(resp => {
                        const urlParts = resp.split("/");
                        const fileName = urlParts.pop();
                        
                        this.fileName = fileName;

                        this.loading = false;

                        this.$emit("input", fileName);
                    })
                    .catch(() => {
                        this.isError = true;
                        this.loading = false;
                    });
            }
        }
    }
</script>
<style lang="scss">
    .picture-input-wrapper {
        height: 320px;
        border: 1px dotted #ccc;
    }
    .upload-btn {
        margin-bottom: 5px !important;
        margin-top: 5px !important;
    }
</style>