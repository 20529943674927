<template>
    <v-card>
        <form @submit="submitForm" novalidate="true">
        <v-card-title class="title">Внесення/редагування результату ІСРС</v-card-title>

        <v-card-text>
            <v-layout class="mb-2" align-center wrap>
                <v-flex xs6 class="text-center font-weight-medium" style="border-right: 1px solid #ccc">
                    <template v-if="individualWorkItemEditLoaded.student != null">
                        <div>
                            <v-avatar class="mb-1" size="80px">
                                <v-icon v-if="gendersEnum.male === individualWorkItemEditLoaded.student.gender">person</v-icon>
                                <v-icon v-if="gendersEnum.female === individualWorkItemEditLoaded.student.gender">face</v-icon>
                            </v-avatar>
                        </div>
                        <div class="title">{{`${individualWorkItemEditLoaded.student.surname} ${individualWorkItemEditLoaded.student.name} ${individualWorkItemEditLoaded.student.patronymic}`}}</div>
                        <div class="subtitle-1">{{individualWorkItemEditLoaded.student.faculty.nameUk}}</div>
                        <div class="subtitle-1">{{individualWorkItemEditLoaded.student.speciality.nameUk}}</div>
                        <div class="subtitle-1">{{individualWorkItemEditLoaded.student.semester.yearNumber}} курс</div>
                        <div class="subtitle-1">{{individualWorkItemEditLoaded.student.academicGroup.groupNumber}} група</div>
                    </template>
                </v-flex>
                <v-flex xs6 class="text-center">
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Сума балів без ІСРС: </span>
                        {{individualWorkItemEditLoaded.marksValueCurrent}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Середня оцінка: </span>
                        {{individualWorkItemEditLoaded.marksValueCurrent}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Сума балів з ІСРС: </span>
                        {{individualWorkItemEditLoaded.marksValueWithIndividualCurrent ? individualWorkItemEditLoaded.marksValueWithIndividualCurrent : "-"}}
                    </div>

                    <v-container align-center align-content-center grid-list-md fluid fill-height>
                    <v-layout row wrap justify-center>
                        <v-flex xs10>
                            <v-select
                                    clearable
                                    align-self-center
                                    :items="individualWorkTypes"
                                    :error-messages="errors.individualWorkType"
                                    v-model="individualWorkItemEditLoaded.individualWorkType"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    label="Виберіть вид ІСРС"
                            ></v-select>
                        </v-flex>
                        <v-flex xs10>
                            <date-picker
                                    v-model="individualWorkItemEditLoaded.classDate"
                                    label="Дата виконання ІСРС"
                                    :error-messages="errors.classDate"
                                    :max-date="new Date()"
                            />
                        </v-flex>
                    </v-layout>
                    </v-container>
                </v-flex>
            </v-layout>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseSaveConfirm')"
                    :disabled="loading"
            >Відміна
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    type="submit"
                    :loading="loading"
                    :disabled="loading"
            >Зберегти
            </v-btn>
        </v-card-actions>
        </form>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import MarkUnsatisfactoryIcons from "../../common/rework-admissions/MarkUnsatisfactoryIcons";
    import MarkInput from "../../../components/common/MarkInput";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import {gendersEnum} from "../../../constants/_internal/gendersEnum";
    import DatePicker from "../../common/DatePicker";

    export default {
        components: {
            MarkUnsatisfactoryIcons,
            MarkInput,
            DatePicker,
        },
        props: {
            individualWorkItem: Object,
            activeModule: Object,
        },
        data: () => {
            return {
                loading: false,
                formatShortDate,
                newMarkValue: {
                    id: null,
                    value: null,
                },
                errors: {
                    individualWorkType: null,
                    classDate: null,
                },
                individualWorkItemEditLoaded: {
                    student: null,
                },
                
                individualWorkTypes: [],
                gendersEnum,
            };
        },
        mounted() {
            HttpService.getInstance()
                .get(`teacher/teacherIndividualWorkMarks/getIndividualWorkForEdit/${this.individualWorkItem.id}/${this.individualWorkItem.student.id}/${this.activeModule.id}`)
                .then(resp => {
                    this.individualWorkItemEditLoaded = resp;
                });

            HttpService.getInstance()
                .get(`individualWorkType/getAllByModule/${this.activeModule.id}`)
                .then(resp => {
                    this.individualWorkTypes = resp.map(r => {
                        r.name = `${r.nameUk} (${r.ectsPoints})`;

                        return r;
                    });
                });
        },
        methods: {
            submitForm(e) {
                e.preventDefault();
                
                this.loading = true;
                
                const individualWorkItemSave = {
                  ...this.individualWorkItemEditLoaded,  
                  module: this.activeModule,
                  department: {id: this.$route.params.department},
                  subject: {id: this.$route.params.subject},  
                };

                HttpService.getInstance()
                    .put(`teacher/teacherIndividualWorkMarks/saveIndividualWorkEdit/${this.individualWorkItemEditLoaded.id}`, individualWorkItemSave)
                    .then(resp => {
                        this.loading = false;

                        this.$emit("saved");
                    })
                    .catch(err => {
                        this.loading = false;
                        
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            }
        }
    }
</script>