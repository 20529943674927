var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"headline"},[_vm._v("Рейтингові види робіт")]),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.pagination,"hide-default-footer":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.isIndividual",fn:function(ref){
var item = ref.item;
return [(!item.isIndividual)?_c('v-icon',[_vm._v("radio_button_unchecked")]):_vm._e(),(item.isIndividual)?_c('v-icon',[_vm._v("radio_button_checked")]):_vm._e()]}},{key:"item.isDepartment",fn:function(ref){
var item = ref.item;
return [(!item.isDepartment)?_c('v-icon',[_vm._v("radio_button_unchecked")]):_vm._e(),(item.isDepartment)?_c('v-icon',[_vm._v("radio_button_checked")]):_vm._e()]}},{key:"item.isEnumerable",fn:function(ref){
var item = ref.item;
return [(!item.isEnumerable)?_c('v-icon',[_vm._v("radio_button_unchecked")]):_vm._e(),(item.isEnumerable)?_c('v-icon',[_vm._v("radio_button_checked")]):_vm._e()]}},{key:"item.isEnglish",fn:function(ref){
var item = ref.item;
return [(!item.isEnglish)?_c('v-icon',[_vm._v("radio_button_unchecked")]):_vm._e(),(item.isEnglish)?_c('v-icon',[_vm._v("radio_button_checked")]):_vm._e()]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"description-mini"},[_vm._v(_vm._s(item.description))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"80px"}},[_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editItem', item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deleteItem', item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }