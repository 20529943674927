<template>
    <div>
        <vue-headful title="eBSMU"/>
        <student-exam-list></student-exam-list>
    </div>
</template>

<script>
    import StudentExamList from "../../components/finalExam/teacherDashboard/StudentExamList";

    export default {
        components: {
            StudentExamList,
        },
        data: () => ({
        }),
        
    }
</script>