<template>
    <div>
        <vue-headful
                title="eBSMU Адміністратори випускного іспиту"
        />
        <admin-final-exam-admin-list-index></admin-final-exam-admin-list-index>
    </div>
</template>

<script>
    import AdminFinalExamAdminListIndex
        from "../../components/finalExam/adminDashboard/finalExamAdminList/AdminFinalExamAdminListIndex";

    export default {
        components: {
            AdminFinalExamAdminListIndex
        },
        data: () => ({
        }),

    }
</script>