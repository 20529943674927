<template>
    <v-container class="px-0" grid-list-md style="position: relative;">
        <block-loader v-if="loadingSave"/>
        <v-layout v-if="loading" align-center justify-center>
            <v-progress-circular
                    :size="150"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </v-layout>
        <v-layout v-if="!loading && !error" row wrap>
            <v-flex xs12 class="center-table-wrapper">
                    <table class="modules-table elevation-3">
                        <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>ПІП студента</th>

                            <th style="max-width: 80px"  class="mark-input-control-cell-header">
                                Середній бал
                            </th>
                            <th style="max-width: 80px"  class="mark-input-control-cell-header">
                                Поточний бал без ІСРС
                            </th>
                            <th class="mark-input-control-cell-header">
                                Загальна оцінка
                            </th>
                            <th class="mark-input-control-cell-header">
                                Тип ІСРС
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(individualWorkView, index) in form.individualWorkViews.individualWorks">
                            <td class="avatar-cell">
                                <v-avatar size="36px">
                                    <v-icon v-if="gendersEnum.male === individualWorkView.student.gender">person</v-icon>
                                    <v-icon v-if="gendersEnum.female === individualWorkView.student.gender">face</v-icon>
                                </v-avatar>
                            </td>
                            <td class="counter-cell input-mark">{{index + 1}}.</td>
                            <td class="student-name-module input-mark">{{individualWorkView.student.surname}}
                                {{individualWorkView.student.name}}
                            </td>

                            <td class="mark-input-control-cell text-center">{{individualWorkView.marksMean}}</td>
                            <td class="mark-input-control-cell text-center">{{individualWorkView.marksValueCurrent}}</td>
                            <td class="mark-input-control-cell text-center">{{individualWorkView.totalPoints}}</td>
                            <td class="mark-input-control-cell  text-center">
                                <div style="min-width: 210px">
                                    <v-select
                                            clearable
                                            :items="individualWorkTypes"
                                            v-model="individualWorkView.individualWorkType"
                                            item-text="name"
                                            item-value="id"
                                            return-object
                                            label="Виберіть вид ІСРС"
                                            hide-details
                                            @change="onChangeIndividualWorkType($event, individualWorkView)"
                                            :error-messages="getModuleError(index, 'individualWorkType')"
                                    ></v-select>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
            </v-flex>
            <v-flex>
                <v-layout justify-center xs12>
                    <v-flex xs6>
                        <date-picker
                                v-model="form.classDate"
                                :max-date="new Date()"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-layout v-if="error" align-center justify-center>
            Виникла помилка при спробі додавання модульного контролю!
        </v-layout>
    </v-container>

</template>

<script>
    import HttpService from "../../../HttpService";
    import BlockLoader from "../../common/BlockLoader";
    import {isNumber} from "../../../helpers/numberHelper";
    import debounce from "lodash/debounce";
    import moduleMarkStatuses, {moduleMarkStatusesEnum} from "../../../constants/moduleMarkStatuses";
    import DatePicker from "../../common/DatePicker";
    import {gendersEnum} from "../../../constants/_internal/gendersEnum";

    export default {
        components: {
            BlockLoader,
            DatePicker
        },
        data: () => ({
            isNumber: isNumber,
            form: {
                individualWorkViews: [],
                classDate: new Date(),
            },
            individualWorkTypes: [],
            loading: false,
            error: false,
            gendersEnum,
        }),
        props: {
            activeModule: {
                type: Object,
                default: {}
            },
            errors: {
                type: Object,
                default: {}
            },
            loadingSave: {
                type: Boolean,
                default: false
            },
        },
        mounted() {
            this.loading = true;
            HttpService.getInstance()
                .get(`teacher/teacherIndividualWorkMarks/individualWorkInputView/${this.$route.params.group}/${this.activeModule.id}`)
                .then(resp => {
                    this.form.individualWorkViews = resp;

                    return HttpService.getInstance()
                        .get(`individualWorkType/getAllByModule/${this.activeModule.id}`);
                })
                .then(resp => {
                    this.individualWorkTypes = resp.map(r => {
                        r.name = `${r.nameUk} (${r.ectsPoints})`;
                        
                        return r;
                    });

                    this.loading = false;
                }).catch((err) => {
                    if (err.errorMessage) {
                        this.error = true;
                    }
                    this.loading = false;
                });
        },
        methods: {
            onChangeIndividualWorkType(value, individualWorkView) {
                if (value) {
                    individualWorkView.totalPoints = individualWorkView.marksValueCurrent + value.ectsPoints;
                    individualWorkView.individualWork = value.ectsPoints;
                }
            },
            getModuleError(studentIndex, fieldName) {
                const moduleErrorRow = this.errors[`modules[${studentIndex}]`];
                if (moduleErrorRow) {
                    const moduleError = moduleErrorRow[fieldName];
                    if (moduleError) {
                        return moduleError;
                    }
                    
                    return "";
                }
                
                return "";
            },
        },
        watch: {
            form: {
                handler: function (newVal) {
                    this.$emit("change", newVal);
                },
                deep: true,
            }
        },
    }
</script>

<style lang="scss">
    .mark-btn {
        font-size: 20px !important;
    }

    .avatar-cell {
        padding: 0px 0px 0px 5px;
    }

    .counter-cell.input-mark {
        padding-left: 0px;
    }

    table.modules-table {
        & td.mark-input-control-cell {
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            padding-left: 8px;
            padding-right: 8px;
            max-width: 235px;

            &:last-child {
                border-right: none;
            }
        }
        
        & td.counter-cell {
            border-bottom: 1px solid #ccc;
        }

        & td.student-name-module {
            max-width: 170px;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;

            & .input-mark {
                padding: 0px 10px 0px 5px;
            }
        }

        & th.mark-input-control-cell-header {
            padding: 0 5px;
            max-width: 235px;
        }
    }

    .mark-topic-error {
        padding: 5px 10px;
        max-width: 230px;
        min-width: 200px;
    }

    .module-points {
        width: 120px;
        padding-top: 10px;

        & .v-text-field.v-text-field--enclosed .v-text-field__details {
            padding: 0;
        }
    }
</style>