<template>
    <v-container fluid>
        <v-layout justify-center>
            <v-container grid-list-md>
                <v-alert
                        v-if="userIsAdminOrEdudeptOrProfessor"
                        outlined
                        type="warning"
                        prominent
                        border="left"
                >
                    <h3 class="text-center">Контакти служби підтримки Еж</h3>
                    <div>З питань поновлення студентів, уточнення списку студентів, логінів-паролів – <a href="tel:+380372526559">(0372) 52-65-59</a>, <a href="mailto:galya.mararash@bsmu.edu.ua">galya.mararash@bsmu.edu.ua</a> (Марараш Г.Г.)</div>
                    <div>З питань дисциплін, навчальних планів – <a href="tel:+380372526559">(0372) 52-65-59</a>, <a href="mailto:yurnuk.slava@bsmu.edu.ua">yurnuk.slava@bsmu.edu.ua</a> (Юрнюк С.В.)</div>
                    <div>З питань рейтингування науково-педагогічних працівників – <a href="tel:+380372573910">(0372) 57-39-10</a>, <a href="mailto:marusyk.uljana@bsmu.edu.ua">marusyk.uljana@bsmu.edu.ua</a> (Марусик У.І.)</div>
                    <div>З технічних питань – <a href="tel:+380372573910">(0372) 57-39-10</a>, <a href="mailto:alex@bsmu.edu.ua">alex@bsmu.edu.ua</a> (Мельничук О.М.)</div>
                    <div>З інших питань – <a href="tel:+380372551630">(0372) 55-16-30</a>, <a href="mailto:khodorovskyi.volodymyr@bsmu.edu.ua">khodorovskyi.volodymyr@bsmu.edu.ua</a> (Ходоровський В.М.)</div>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <div class="body-2">УВАГА! Немає потреби контактувати з іншими відповідальними особами за роботу Еж. 
                        При необхідності, Ваше запитання/проблему буде автоматично перенаправлено до інших спеціалістів!</div>
                </v-alert>
                <student-support-contacts></student-support-contacts>
                
                <navigation-cards/>
                <announcements-list/>
                <surveys-list/>
                <birthdays-list/>
            </v-container>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationCards from "../../components/dashboard/NavigationCards";
    import AnnouncementsList from "../../components/dashboard/AnnouncementsList";
    import SurveysList from "../../components/dashboard/SurveysList";
    import BirthdaysList from "../../components/dashboard/BirthdaysList";
    import {rolesEnum} from "../../constants/rolesEnum";
    import StudentSupportContacts from "../../components/dashboard/StudentSupportContacts";

    export default {
        components: {
            NavigationCards,
            AnnouncementsList,
            SurveysList,
            BirthdaysList,
            StudentSupportContacts,
        },
        methods: {
            clickBtn() {
                this.$auth.login();
            },
            logOut() {
                this.$auth.logout();
            },
            userIsAdminOrEdudeptOrProfessor() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.edudept, rolesEnum.professor]);
            },
        },
    }
</script>
