var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"headline"},[_vm._v("Теми занять")]),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.pagination,"footer-props":{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.isBlocked",fn:function(ref){
            var item = ref.item;
return [(!item.isBlocked)?_c('v-icon',[_vm._v("radio_button_unchecked")]):_vm._e(),(item.isBlocked)?_c('v-icon',[_vm._v("radio_button_checked")]):_vm._e()]}},{key:"item.evaluationMode",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEvaluationModeType(item.evaluationMode))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"80px"}},[_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editItem', item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deleteItem', item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }