<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            loading-text="Loading... Please wait"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
               itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.nameSpec="{item}">
            {{item.finalExamName.name}} ({{item.finalExamName.speciality.nameUk}})
        </template>
        
        <template v-slot:item.date="{item}">
            {{formatShortDate(item.date)}}
        </template>
        
        <template v-slot:item.academicGroups="{item}">
            <v-chip color="primary" :key="index" v-for="(group, index) in item.academicGroups">
                {{group.groupNumber}} група
            </v-chip>
        </template>
        
        <template v-slot:item.actions="{item}">
<!--            <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">-->
<!--                <v-icon color="primary">edit</v-icon>-->
<!--            </v-btn>-->
            <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                <v-icon color="red">delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>
<script>
    import {formatShortDate} from "../../../../helpers/dateHelper";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Назва та спеціальність', value: 'nameSpec'},
                {text: 'Дата', value: 'date'},
                {text: 'Академічні групи', value: 'academicGroups'},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
            formatShortDate,
        }),
    }
</script>