export const topicMarkAdmissionTypesEnum = {
    noValidReason: 1,
    validReason: 2,
    sickness: 3,
    withPromotion: 4,
};

export default [
    {
        id: topicMarkAdmissionTypesEnum.noValidReason,
        nameUk: "Без поважної причини",
        nameEn: "Without valid reason",
        icon: 'error_outline'
    },
    {
        id: topicMarkAdmissionTypesEnum.validReason,
        nameUk: "З поважної причини",
        nameEn: "With valid reason",
        icon: 'add_circle_outline'
    },
    // {
    //     id: topicMarkAdmissionTypesEnum.sickness,
    //     nameUk: "Хвороба", 
    //     nameEn: "Sickness",
    //     icon: 'local_hospital'
    // },
    // {
    //     id: topicMarkAdmissionTypesEnum.withPromotion,
    //     nameUk: "Зі сприянням",
    //     nameEn: "With promotion",
    //     icon: 'thumb_up_alt'
    // },
];
