<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування інфо про відробітки' : 'Додавання інфо про відробітки'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-layout>
                                <v-flex xs6>
                                    <time-picker
                                            v-model="form.reworkStartTime"
                                            :error-messages="errors.reworkStartTime"
                                            label="Час початку відробітків"
                                    ></time-picker>
                                </v-flex>
                                <v-flex xs6>
                                    <time-picker
                                            v-model="form.reworkEndTime"
                                            :error-messages="errors.reworkEndTime"
                                            label="Час завершення відробітків"
                                    ></time-picker>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.reworkUrl" v-model="form.reworkUrl"
                                          label="URL відробітків"></v-text-field>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../HttpService";
    import moment from 'moment';
    import {remapErrors} from "../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import TimePicker from "../../common/TimePicker";

    export default {
        props: {
            editItem: Object,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
            TimePicker,
        },
        data: () => {
            return {
                form: {
                    reworkStartTime: null,
                    reworkEndTime: null,
                    reworkUrl: null,
                    reworkScheduleId: null,
                    scheduleItems: null,
                },
                errors: {
                    reworkStartTime: [],
                    reworkEndTime: [],
                    reworkUrl: [],
                },
                loading: false,
                loadingSave: false,
                
                minDate: null,
                maxDate: null,
            }
        },
        mounted() {
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`teacher/teacherReworkSchedule/${this.editItem.reworkScheduleId}`)
                    .then(resp => {
                        this.loading = false;
                        const respRemapped = resp;
                        
                        const editDateRemapped = moment(this.editItem.date).format("DD/MM/YYYY");
                        
                        respRemapped.reworkStartTime = respRemapped.reworkStartTime === null ? 
                            moment(`${editDateRemapped} 19:00`, "DD/MM/YYYY HH:mm").toDate() : respRemapped.reworkStartTime;
                        respRemapped.reworkEndTime = respRemapped.reworkEndTime === null ?
                            moment(`${editDateRemapped} 22:00`, "DD/MM/YYYY HH:mm").toDate() : respRemapped.reworkEndTime;
                        
                        this.form = respRemapped;
                    })
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;
                
                const saveItem = {...this.form, scheduleItems: this.editItem.scheduleItems};

                HttpService.getInstance()
                    .post("teacher/teacherReworkSchedule/", saveItem)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        },
    }
</script>