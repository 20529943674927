<template>
    <v-card>
        <v-card-title class="title">Видалення всіх результатів ІСРС з вибраного модулю</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете видалити всіх результатів ІСРС з вибраного модулю?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldClose')"
                    :disabled="loadingDelete"
            >Ні
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="removeItem"
                    :loading="loadingDelete"
                    :disabled="loadingDelete"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => {
            return {
                loadingDelete: false,
            };
        },
        props: {
            moduleId: {
                type: Number,
            }
        },
        methods: {
            removeItem() {
                this.loadingDelete = true;

                const groupId = this.$route.params.group;
                const moduleId = this.moduleId;

                HttpService.getInstance()
                    .delete(`teacher/teacherIndividualWorkMarks/removeAllIndividualWorkMarks/${groupId}/${moduleId}`)
                    .then(resp => {
                        this.loadingDelete = false;

                        this.$emit("removed");
                    })
                    .catch(() => {
                        this.loadingDelete = false;
                    })
            }
        }
    }
</script>