var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"headline"},[_vm._v("Модулі")]),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.pagination,"footer-props":{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"px-2"},[_c('div',{staticClass:"justify-center d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('navigateTo', props.item.id, 'topicsPlan')}}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("assignment")])],1)]}}],null,true)},[_c('span',[_vm._v("Перейти до списку тем")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('navigateTo', props.item.id, 'individualWorkPlan')}}},on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("done_all")])],1)]}}],null,true)},[_c('span',[_vm._v("Перейти до списку видів ІСРС")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('navigateTo', props.item.id, 'lecturesPlan')}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("speaker_notes")])],1)]}}],null,true)},[_c('span',[_vm._v("Перейти до списку лекційних занять")])])],1)]),_c('td',{staticClass:"text-xs-left"},[(props.item.isWarning)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"orange darken-1"}},on),[_vm._v("report_problem")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.warningMessage))])]):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(props.item.orderBy))])],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.nameUk))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.nameEn))]),_c('td',{staticClass:"text-xs-left"},[(props.item.isValidationError)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red darken-1"}},on),[_vm._v("error")])]}}],null,true)},[_c('span',[_vm._v("Бали ECTS за модульний контроль НЕ є валідними. Перевірте ІСРС. Модульний контроль не буде відображатися для користувачів!")])]):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(props.item.topicsEvaluableCount))])],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.topicsAllCount))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.mapFinalControlType(props.item.finalControlType)))]),_c('td',{staticClass:"text-xs-left"},[(!props.item.isBlocked)?_c('v-icon',[_vm._v("radio_button_unchecked")]):_vm._e(),(props.item.isBlocked)?_c('v-icon',[_vm._v("radio_button_checked")]):_vm._e()],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.ects5))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.ects4))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.ects3))]),_c('td',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editItem', props.item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deleteItem', props.item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1)],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }