export default {
    message: {
        hello: 'hello world'
    },
    announcements: {
        title: "Announcements",
        norecords: "There are no actual announcements at this moment...",
        types: {
            educationalDpt: "Educational department",
            deanOffice: "Dean office",
            department: "Department",
            curator: "Curator",
        }
    },
    dashboard: {
        "loginButtonTextFull": "Log in into Electronic register",
        "loginButtonTextShort": "Log in",
        "testVersionExclamation": "This version is for test purposes only!!! Please use <a href=\"https://eznew.bsmu.edu.ua\">eznew.bsmu.edu.ua</a>!!!",
        "supportTitle": "Electronic register support",
        "accountQuestions": "On password and account recovery, inaccuracies in the account",
        "technicalQuestions": "On technical issues",
        "postNote": "WARNING! There is no need to contact other persons responsible for the work of Electronic register. " +
            "If necessary, your question / problem will be automatically forwarded to other specialists!",
        "cannotLogIn": "Cannot log in?",
    },
    surveys: {
        title: "Surveys",
        norecords: "There are no actual surveys at this moment...",
    },
    toolbar: {
        announcements: "Announcements",
        surveys: "Surveys",
        logOut: "Log off",
        settings: "Settings"

    },
    navigation: {
        student: {
            title: "eBSMU Student",
            description: "All your marks in BSMU"
        },
        admin: {
            title: "eBSMU Admin",
            description: "Admin dashboard"
        },
        dean: {
            title: "eBSMU Dean",
            description: "Dean dashboard"
        },
        chiefdept: {
            title: "eBSMU ChiefDept",
            description: "ChiefDept dashboard"
        },
        moderator: {
            title: "eBSMU Moderator",
            description: "Moderator dashboard"
        },
        teacher: {
            title: "eBSMU Teacher",
            description: "Teacher dashboard"
        },
        rating: {
            title: "eBSMU Rating",
            description: "Rating dashboard"
        },
        ratingAdmin: {
            title: "eBSMU Rating:Admin",
            description: "Rating admin dashboard"
        },
        reworks: {
            title: "Reworks",
            description: "Reworks dashboard"
        },
        labstaff: {
            title: "eBSMU Laborant",
            description: "Laborants dashboard"
        },
        teacherFeedback: {
            title: "eBSMU Feedback",
            description: "Feedback dashboard"
        },
        finalExam: {
            title: "Graduation exam",
            description: "Graduation exam dashboard",
        },
    },

    finalExam: {
        infoAbout: "Info about graduation exams",
        notYetRegistered: "You are not yet registered for the exam",
        registerForExam: "Register for the exam",
        youAreRegistered: "You have registered for the exam",
        examName: "Exam name",
        googleMeetLinkExam: "GoogleMeet link for passing the exam",
        linkShow10MinsBeforeExam: "The link will be provided 10 minutes before the exam",
        navigateToExam: "Navigate to the exam",
        noMeetLinkAvailable: "No GoogleMeet link",
        dateAndTimeExam: "Date and time of the exam",
        dateAndTimeExamTimezone: "Kyiv time (GMT+3)",
        examinationPaperNumber: "Examination paper number",
        ticketNumberWillBeShown10Mins: "The paper number will be indicated 10 minutes before the exam",
        passed: "Passed",
        notPassed: "Not passed",
        resultGraduationExams: "The results of the graduation exams",
        noGraduationExamResults: "There are no exam results",
        examResultTraditional: "Exam result (traditional mark)",
        noPublishedResult: "There are no published results",
        examResultEcts: "Exam result (ECTS)",
        menuFinalExamStudentDashboard: "Graduation exams",
        menuFinalExamResults: "Graduation exams results",
        chooseMenuItemOnLeft: "Choose menu item on the left side",
        stStatus: "Status",
        resultKrokExam: "The result of KROK exam"
    },
}