var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"orange"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","dark":"","tabs":""},nativeOn:{"click":function($event){return _vm.$emit('shouldCloseAddEdit')}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[_vm._v("Незараховані ПМК для "+_vm._s(_vm.student.surname)+" "+_vm._s(_vm.student.name))]),_c('v-spacer')],1),_c('v-container',{staticStyle:{"height":"100%"},attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs7":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"align-center",attrs:{"xs5":""}},[_c('date-picker',{attrs:{"label":"Дата заняття (від)"},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}})],1),_c('v-flex',{attrs:{"xs5":""}},[_c('date-picker',{attrs:{"label":"Дата заняття (до)"},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}})],1),_c('v-flex',{staticClass:"justify-end",attrs:{"xs2":""}},[_c('div',{staticClass:"d-flex align-center align-content-center"},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.getItems()}}},[_vm._v("Застосувати")])],1)])],1)],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.reworkModuleItems,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subject.nameUk)+" ")]}},{key:"item.module",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.module.orderBy)+". "+_vm._s(item.module.nameUk)+" ")]}},{key:"item.classDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatShortDate(item.moduleControlMark.classDate))+" ")]}},{key:"item.currentPoints",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moduleControlMark.marksValueCurrent)+" ")]}},{key:"item.moduleControlPoints",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moduleControlMark.moduleControlPoints)+" ")]}},{key:"item.totalPoints",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moduleControlMark.totalPoints)+" ")]}},{key:"item.totalTraditional",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moduleControlMark.totalTraditional)+" ")]}},{key:"item.totalAbc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moduleControlMark.totalAbc)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.couldReworkModule)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.openReworkDialog(item)}}},on),[_c('v-icon',[_vm._v("done_outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Перескласти модульний контроль")])]):_vm._e()]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"1100","scrollable":""},model:{value:(_vm.confirmRework),callback:function ($$v) {_vm.confirmRework=$$v},expression:"confirmRework"}},[(_vm.confirmRework)?_c('module-rework-dialog',{attrs:{"student":_vm.student,"rework-item":_vm.selectedReworkItem},on:{"shouldCloseSaveConfirm":function($event){_vm.confirmRework = false},"saved":_vm.savedAdmission}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }