var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.pagination,"footer-props":{
            itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.semesterYear",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((item.semester.yearNumber) + " курс / " + (item.semester.semesterNumber) + " семестр"))+" ")]}},{key:"item.username",fn:function(ref){
        var item = ref.item;
return [(item.username !== null)?_c('div',[_vm._v(_vm._s(item.username))]):_vm._e(),(item.username === null)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("remove")])]}}],null,true)},[_c('span',[_vm._v("Немає створеного аккаунту")])])],1):_vm._e()]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [(item.email !== null)?_c('div',[_vm._v(_vm._s(item.email))]):_vm._e(),(item.email === null)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("remove")])]}}],null,true)},[_c('span',[_vm._v("Немає створеного аккаунту")])])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.navigateToRegisters(item)}}},[_c('v-list-item-title',[_vm._v("Переглянути оцінки")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.navigateToStudentManagement(item)}}},[_c('v-list-item-title',[_vm._v("Перейти до управління студентами")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.navigateToStudentPasswordCreation(item)}}},[_c('v-list-item-title',[_vm._v("Перейти до створення аккаунтів")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }