var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.students,"server-items-length":_vm.students.length,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.student",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(item.student.surname)+" "),_c('span',{class:{'blured': _vm.isBlur || _vm.isBlurAfter}},[_vm._v(" "+_vm._s(item.student.name)+" "+_vm._s(item.student.patronymic))])]),_c('div',[_vm._v(_vm._s(item.student.academicGroup.groupNumber)+" група")])]}},{key:"item.isCheckedIn",fn:function(ref){
var item = ref.item;
return [(item.isCheckedIn)?_c('div',{staticClass:"text--darken-4 green--text"},[_c('v-icon',[_vm._v("done")])],1):_vm._e(),(!item.isCheckedIn)?_c('v-icon',[_vm._v("clear")]):_vm._e()]}},{key:"item.finalExams",fn:function(ref){
var item = ref.item;
return _vm._l((item.finalExams),function(exam,index){return _c('v-card',{key:(index + "_" + (item.id)),staticClass:"d-inline-block ma-1",class:{
                            'green lighten-3 is-passed': exam.isPassed,
                            'orange lighten-3': !exam.isPassed && _vm.checkIfNowExamining(exam)
                        },staticStyle:{"width":"250px"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('strong',_vm._g({},on),[_vm._v(_vm._s(exam.name.substring(0, 25))+"...")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(exam.name))])])],1),_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(_vm._s(_vm.formatLongDate(exam.examDateTime)))]),_c('v-card-text',{staticClass:"pb-1"},[(exam.examiner.meetLink !== null && exam.examiner.meetLink !== '')?_c('a',{staticClass:"d-block text-truncate",class:{'blured': _vm.isBlur},attrs:{"href":exam.examiner.meetLink,"target":"_blank"}},[_vm._v(" "+_vm._s(exam.examiner.professor.surname)+" "+_vm._s(exam.examiner.professor.name)+" "+_vm._s(exam.examiner.professor.patronymic)+" ")]):_vm._e(),(exam.examiner.meetLink === null || exam.examiner.meetLink === '')?_c('div',{staticClass:"d-block text-truncate",class:{'blured': _vm.isBlur}},[_vm._v(" "+_vm._s(exam.examiner.professor.surname)+" "+_vm._s(exam.examiner.professor.name)+" "+_vm._s(exam.examiner.professor.patronymic)+" ")]):_vm._e()]),_c('v-card-text',{staticClass:"pt-1 pb-1"},[_c('v-chip',{class:{'blured': _vm.isBlurAfter},attrs:{"color":_vm.getMarkColor(+exam.totalClassicValue, _vm.isBlurAfter),"dark":""}},[_vm._v(" "+_vm._s(exam.totalClassicValue)+" ")]),_c('v-chip',{staticClass:"ml-2",class:{'blured': _vm.isBlurAfter},attrs:{"color":"orange lighten-3"}},[_vm._v(_vm._s(exam.totalValue.toFixed(2)))])],1),_c('v-card-actions',{staticClass:"d-flex justify-center border_top_actions"},[_c('v-tooltip',{staticClass:"ml-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showDetailFn(exam.id)}}},on),[_c('v-icon',[_vm._v("remove_red_eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Переглянути протокол")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-5",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.printProtocolFn(exam.id)}}},on),[_c('v-icon',[_vm._v("print")])],1)]}}],null,true)},[_c('span',[_vm._v("Роздрукувати протокол")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(exam.finalExamScreenshot)?{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-5",attrs:{"color":"orange darken-3","small":"","icon":""},on:{"click":function($event){return _vm.showScreenShotFn(exam.finalExamScreenshot)}}},on),[_c('v-icon',[_vm._v("image")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Переглянути скріншот")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(exam.finalExamScreenshot === null)?{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-5",attrs:{"disabled":"","color":"gray","small":"","icon":""}},on),[_c('v-icon',[_vm._v("image")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Немає скріншоту")])])],1)],1)})}}])}),_c('v-dialog',{attrs:{"max-width":"1100"},model:{value:(_vm.printDialog),callback:function ($$v) {_vm.printDialog=$$v},expression:"printDialog"}},[(_vm.printDialog)?_c('print-protocol-dialog',{attrs:{"exam-id":this.printExamId},on:{"close":function($event){return _vm.closePrintProtocol()}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"1300"},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[(_vm.detailDialog)?_c('v-card',[_c('v-container',[_c('v-layout',[_c('student-exam-detail',{attrs:{"is-from-admin":"","exam-id":+this.detailExamId,"is-set-started":false}})],1)],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"1300"},model:{value:(_vm.screenShotDialog),callback:function ($$v) {_vm.screenShotDialog=$$v},expression:"screenShotDialog"}},[(_vm.screenShotDialog)?_c('preview-screen-shot',{attrs:{"photo-url":_vm.screenShotUrlId}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }