<template>
    <v-container
            fluid
            grid-list-lg
    >
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense two-line>
                            <v-subheader class="mt-4 grey--name name--darken-1 subtitle-1">Дисципліни</v-subheader>
                            <navigation-list-template :loading="isSubjectLoading" :items="subjects"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card v-if="!isSelectedSubject">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть дисципліну
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                <marks-wrapper v-if="isSelectedSubject" :key="$route.fullPath"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../../HttpService";
    import MarksWrapper from "../../../components/teacher/marks/MarksWrapper";
    import NavigationListTemplate from "../../../components/common/NavigationListTemplate";

    export default {
        components: {
            MarksWrapper,
            NavigationListTemplate
        },
        props: {
            routeTo: {
                type: String,
                defaultsTo: "registers"
            },
        },
        data: () => ({
            isSelectedSubject: false,
            isSubjectLoading: false,
            subjects: []
        }),
        mounted() {
            this.isSelectedSubject = this.$route.params.subject !== undefined;

            if (this.$route.params.group !== undefined) {
                this.getSubjects();
            }
        },
        watch: {
            $route: function (route, prevRoute) {
                if (route.params.subject !== prevRoute.params.subject) {
                    this.isSelectedSubject = this.$route.params.subject !== undefined;
                }

                if (route.params.group !== prevRoute.params.group) {
                    this.getSubjects();
                }
            }
        },
        methods: {
            getSubjects() {
                this.isSubjectLoading = true;
                
                HttpService.getInstance()
                    .get(`commonNavigation/subjectByAcademicGroup/${this.$route.params.group}`)
                    .then(res => {
                        this.subjects = res.map(subject => {
                            subject.name = `${subject.nameUk} (${subject.nameShortUk})`;
                            subject.route = {
                                name: this.routeTo,
                                params: {
                                    faculty: this.$route.params.faculty,
                                    speciality: this.$route.params.speciality,
                                    year: this.$route.params.year,
                                    group: this.$route.params.group,
                                    subject: subject.id,
                                }
                            };

                            return subject;
                        });
                        this.isSubjectLoading = false;
                    });
            },
        },
    }
</script>