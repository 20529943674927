<template>
    <div>
        <div class="headline">Види індивідуальної самостійної роботи студента</div>
        <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :options.sync="pagination"
                class="elevation-3"
                :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
                }"
        >
            <template v-slot:item="props">
                <tr>
                    <td class="text-xs-left">{{ props.item.nameUk }}</td>
                    <td class="text-xs-left">{{ props.item.nameEn }}</td>
                    <td class="text-xs-left">{{ props.item.ectsPoints }}</td>
                    <td class="text-xs-left">
                        <div class="d-flex justify-center">
                            <v-btn icon class="mx-0" @click="$emit('editItem', props.item.id)">
                                <v-icon color="primary">edit</v-icon>
                            </v-btn>
                            <v-btn icon class="mx-0" @click="$emit('deleteItem', props.item.id)">
                                <v-icon color="red">delete</v-icon>
                            </v-btn>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import finalControlTypes from "../../../constants/admin/finalControlTypes";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Назва (укр)', value: 'nameUk'},
                {text: 'Назва (eng)', value: 'nameEn'},
                {text: 'Бали ECTS', value: 'ectsPoints'},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>