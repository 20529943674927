import Oidc from 'oidc-client';
import Vue from 'vue';
import config from "../app.config";
import {AppConfig} from "../config/configFactory";
import jwtDecode from "jwt-decode";
import intersectionWith from "lodash/intersectionWith";
import router from "../router";
import lodash from "lodash";
import * as Sentry from '@sentry/browser';

let configuration = AppConfig(config);

let oidcMgr = new Oidc.UserManager({
    // userStore: new Oidc.WebStorageStateStore(),
    authority: configuration.oidc.authority,
    client_id: configuration.oidc.clientId,
    redirect_uri: configuration.oidc.redirectUri,
    response_type: configuration.oidc.responseType,
    scope: configuration.oidc.scope,
    post_logout_redirect_uri: configuration.oidc.postLogoutRedirectUri,
    silent_redirect_uri: configuration.oidc.silentRedirectUri,
    automaticSilentRenew: configuration.oidc.automaticSilentRenew,
    monitorSession: true,
    loadUserInfo: configuration.oidc.loadUserInfo,
    revokeAccessTokenOnSignout: configuration.oidc.revokeAccessTokenOnSignout,
    accessTokenExpiringNotificationTime: 120,
    // popup_redirect_uri: configuration.oidc.redirectUri,

});

Oidc.Log.logger = window.console;
Oidc.Log.level = Oidc.Log.ERROR;

function deleteCookie(cname) {
    const d = new Date(); //Create an date object
    d.setTime(d.getTime() - (1000*60*60*24)); //Set the time to the past. 1000 milliseonds = 1 second
    const expires = "expires=" + d.toGMTString(); //Compose the expirartion date
    window.document.cookie = cname + "=" + "; " + expires;//Set the cookie with name and the expiration date

}

function logOut() {
    return new Promise((resolve, reject) => {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user');
        localStorage.clear();
        
        // oidcMgr.removeUser(); // ???
        oidcMgr.signoutRedirect();

        deleteCookie('returnUrl');
        
        router.push("/unauthorized");

        resolve();
    });
}

let auth = new Vue({
    created() {
        oidcMgr.events.addUserLoaded(user => {
            this.expiresAt = user.expires_at;
            this.accessToken = user.access_token;
            this.token = user.id_token;
            this.user = user.profile;
        });

        oidcMgr.events.addUserUnloaded(() => {
        });
        oidcMgr.events.addUserSignedOut(() => {
            this.logout();
        });
        oidcMgr.events.addAccessTokenExpiring(() => {
            console.log("Access token expiring...");
        });
        oidcMgr.events.addAccessTokenExpired(() => {
            console.log("token expired");
            // this.logout();
        });
        oidcMgr.events.addSilentRenewError((err) => {
            console.log("Silent renew error: " + err.message);
        });
        oidcMgr.events.addUserSignedOut(() => {
            console.log("User signed out of OP");
            this.logout();
        });
        
        window.addEventListener('storage', (event) => {
            if (event.storageArea === localStorage) {                
                if (!this.accessToken || this.isAuthenticated()) {
                    this.silentRefresh();
                }
            }
        }, false);
    },
    computed: {
        token: {
            get: () => {
                return localStorage.getItem('id_token');
            },
            set: (id_token) => {
                localStorage.setItem('id_token', id_token);
            }
        },
        accessToken: {
            get: () => {
                return localStorage.getItem('access_token');
            },
            set: (accessToken) => {
                localStorage.setItem('auth_token', JSON.stringify(jwtDecode(accessToken)));
                localStorage.setItem('access_token', accessToken);
            }
        },
        expiresAt: {
            get: () => {
                return localStorage.getItem('expires_at');
            },
            set: (expiresAt) => {
                let expiresAtTime = JSON.stringify(expiresAt * 1000);
                localStorage.setItem('expires_at', expiresAtTime);
            }
        },
        user: {
            get: () => {
                return JSON.parse(localStorage.getItem('user'));
            },
            set: (user) => {
                localStorage.setItem('user', JSON.stringify(user));
            }
        }
    },
    methods: {
        login(locale) {
            localStorage.clear();
            
            oidcMgr.signinRedirect({
                extraQueryParams: {
                    language: locale,
                }
            });
        },
        silentRefresh() {
            oidcMgr.signinSilent()
                .then((value) => {
                    console.log("silentRefresh", value);
                })
                .catch((err) => {
                    // console.log(err); 
                });
        },
        logout() {
            return logOut();
        },
        async getUser() {
            return await oidcMgr.getUser();
        },
        getUsername() {
            const authTokenStorage = localStorage.getItem('auth_token');
            const user = JSON.parse(authTokenStorage);
            
            if(user === null) {
                return '';
            }
            
            return user.name;
        },
        getSub() {
            const authTokenStorage = localStorage.getItem('auth_token');
            const user = JSON.parse(authTokenStorage);

            if(user === null) {
                return '';
            }

            return user.sub;
        },
        
        isOauth() {
            const authToken = JSON.parse(localStorage.getItem('auth_token'));

            return authToken.googleId !== undefined;  
        },
        isAuthenticated() {
            return new Date().getTime() < localStorage.getItem('expires_at');
        },
        handleAuthentication() {
            return new Promise((resolve, reject) => {
                return oidcMgr.signinRedirectCallback()
                    .then((user) => {

                        Sentry.configureScope((scope) => {
                            scope.setUser(
                                {
                                    "id": this.getSub(),
                                    "username": this.getUsername()
                                }
                            );

                            scope.setLevel('error');
                        });
                        
                        resolve(user);
                    })
                    .catch(error => {
                        // this.logout();
                        reject(error);
                    });
            });
        },
        handleSilentAuthentication() {
            return new Promise((resolve, reject) => {
                oidcMgr.signinSilentCallback()
                    .then(() => {
                        resolve();
                    })
                    .catch(error => {
                        this.logout();
                        reject(error);
                    }); 
            });
        },
        getRoles() {
            const authToken = JSON.parse(localStorage.getItem('auth_token'));
            if (authToken === null) {
                return [];
            }
            return typeof authToken.role === "string" ? [authToken.role] : authToken.role;
        },
        userHasRole(roleName) {
            const roles = this.getRoles();
            
            return roles.indexOf(roleName) > -1;
        },
        userHasAtLeastOneRole(rolesToCheck) {
            const roles = this.getRoles();
          
            const intersection = lodash.intersection(roles, rolesToCheck);
            
            return intersection.length > 0;
        },
        hasRoles(rolesStr) {
            let role = typeof rolesStr === "string" ? [rolesStr] : rolesStr;

            return intersectionWith(role, this.getRoles(), (a, b) => {
                return a === b;
            }).length > 0;
        }
    }
});

export default {
    install: function (Vue) {
        Vue.prototype.$auth = auth;
    }
}