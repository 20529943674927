<template>
    <div style="position: relative">
        <block-loader v-if="loading"/>
        <v-container class="register-container">
            <v-layout justify-center>
                <v-flex xs12>
                    <div v-if="moduleInfo.classDate" class="subtitle-1 text-center">
                        <v-icon>event_available</v-icon>
                        {{formatShortDate(moduleInfo.classDate)}}
                    </div>
                    <div v-if="moduleInfo.professor" class="subtitle-1 text-center">
                        <v-icon>how_to_reg</v-icon>
                        {{moduleInfo.professor.surname}} {{moduleInfo.professor.name}}
                        {{moduleInfo.professor.patronymic}}
                    </div>
                </v-flex>
            </v-layout>
            <v-layout justify-center>
                <v-flex xs12 class="scrollable">
                    <table class="marks-table elevation-3 mb-6">
                        <thead>
                        <tr>
                            <th colspan="2" class="student-name corner-left-bottom">
                                ПІП студента
                            </th>
                            <th class="header-cell">
                                Кількість "2"
                            </th>
                            <th class="header-cell">
                                Кількість "нб"
                            </th>
                            <th class="header-cell">
                                Середній бал
                            </th>
                            <th class="header-cell">
                                Поточна успішність
                            </th>
                            <th class="header-cell" v-if="activeModule.finalControlType === finalControlTypesEnum.finalModuleControl">
                                Бали за ПМК
                            </th>
                            <th class="header-cell">
                                Загальна сума балів
                            </th>
                            <th class="header-cell">
                                Традиційна оцінка
                            </th>
                            <th class="header-cell">
                                Статус
                            </th>
                            <th v-if="userIsAdminOrEdudept()" class="header-cell">
                                Дії
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(modInfo, index) in moduleInfo.moduleControlStudentInfos">
                            <td class="counter-cell">{{index + 1}}.</td>
                            <td class="student-name">
                                {{modInfo.student.surname}} {{modInfo.student.name}}
                            </td>
                            <td class="cell">{{modInfo.unsatisfactoryCount}}</td>
                            <td class="cell">{{modInfo.absencesCount}}</td>
                            <td class="cell">{{modInfo.markValueMean ? modInfo.markValueMean.toFixed(2) : ""}}
                            </td>
                            <td class="cell">
                                <div class="subtitle-1">{{modInfo.marksValueCurrent ?
                                    modInfo.marksValueCurrent.toFixed(2) : ""}}
                                </div>
                            </td>
                            <td :class="getMarkClass(modInfo)" v-if="activeModule.finalControlType === finalControlTypesEnum.finalModuleControl">
                                {{(modInfo.moduleControlMark) ? modInfo.moduleControlMark.moduleControlPoints : "-"}}
                            </td>
                            <td :class="getMarkClass(modInfo)">
                                {{(modInfo.moduleControlMark) ? modInfo.moduleControlMark.totalPoints : "-"}}
                            </td>
                            <td :class="getMarkClass(modInfo)">
                                {{(modInfo.moduleControlMark) ? modInfo.moduleControlMark.totalTraditional : "-"}}
                            </td>
                            <td :class="getMarkClass(modInfo)" style="font-size: 12px;">
                                <template v-if="modInfo.moduleControlMark">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on}">
                                            <v-icon v-on="on" color="white">{{getModuleMarkStatusIcon(modInfo.moduleControlMark.stateType)}}</v-icon>
                                        </template>
                                        <span>{{getModuleMarkStatusText(modInfo.moduleControlMark.stateType)}}</span>
                                    </v-tooltip>
                                </template>
                            </td>
                            <td
                                    v-if="userIsAdminOrEdudept()"
                                    class="pl-1 pr-1"
                            >
                                <div class="d-flex justify-start" v-if="modInfo.moduleControlMark && modInfo.moduleControlMark.classDate">
                                    <v-btn @click="deleteItem(modInfo)" icon class="mx-0">
                                        <v-icon color="red">delete</v-icon>
                                    </v-btn>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="addModuleDialog" scrollable max-width="1150px">
            <add-module-dialog
                    v-if="addModuleDialog"
                    :activeModule="activeModule"
                    @shouldSaveAndClose="moduleAdded"
                    @shouldClose="addModuleDialog = false"
            ></add-module-dialog>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="900px">
            <module-delete-confirm
                    v-if="dialogRemove"
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            ></module-delete-confirm>
        </v-dialog>

        <v-dialog v-model="dialogRemoveAll" max-width="900px">
            <module-delete-all-confirm
                    v-if="dialogRemoveAll"
                    :module-id="activeModule.id"
                    @shouldClose="dialogRemoveAll = false"
                    @removed="removedAll"
            >
            </module-delete-all-confirm>
        </v-dialog>

        <v-btn
                v-if="!moduleInfo.classDate && userIsTeacher()"
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addModuleDialog = true"
        >
            <v-icon>add</v-icon>
        </v-btn>

        <v-speed-dial
                dark
                fixed
                bottom
                right
                fab
                direction="top"
                v-model="fab"
                v-if="userIsAdminOrEdudept()"
        >
            <template v-slot:activator>
                <v-btn
                        color="red darken-1"
                        dark
                        fab
                >
                    <v-icon v-if="fab">close</v-icon>
                    <v-icon v-else>add</v-icon>
                </v-btn>
            </template>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            v-if="!moduleInfo.classDate"
                            fab
                            dark
                            small
                            color="primary"
                            @click="addModuleDialog = true"
                    >
                        <v-icon>add</v-icon>
                    </v-btn>
                </template>
                <span>Додати результати модульного контролю</span>
            </v-tooltip>

            <v-tooltip bottom v-if="userIsAdminOrEdudept()">
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            fab
                            dark
                            small
                            color="red"
                            @click="dialogRemoveAll = true"
                    >
                        <v-icon>delete</v-icon>
                    </v-btn>
                </template>
                <span>Видалити ВСІ результати заключного модульного контролю</span>
            </v-tooltip>
        </v-speed-dial>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import AddModuleDialog from "./AddModuleDialog";
    import BlockLoader from "../../common/BlockLoader";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import {rolesEnum} from "../../../constants/rolesEnum";
    import moduleMarkStatuses, {moduleMarkStatusesEnum} from "../../../constants/moduleMarkStatuses";
    import ModuleDeleteConfirm from "./ModuleDeleteConfirm";
    import ModuleDeleteAllConfirm from "./ModuleDeleteAllConfirm";
    import finalControlTypes, {finalControlTypesEnum} from "../../../constants/admin/finalControlTypes";

    export default {
        components: {
            AddModuleDialog,
            BlockLoader,
            ModuleDeleteConfirm,
            ModuleDeleteAllConfirm,
        },
        props: {
            activeModule: {
                type: Object
            }
        },
        data() {
            return {
                fab: false,
                addModuleDialog: false,

                dialogRemove: false,
                deleteId: null,
                
                dialogRemoveAll: false,
                
                moduleInfo: {
                    moduleControlStudentInfos: [],
                },
                loading: false,
                formatShortDate,
                finalControlTypesEnum,
            }
        },
        mounted() {
            this.$ga.event('professor', 'getModuleList', 'groupId', this.$route.params.group);

            this.getModule();
        },
        methods: {
            getMarkClass(modInfo) {
                if (modInfo.moduleControlMark && modInfo.moduleControlMark.totalTraditional) {
                    return `mark mark${modInfo.moduleControlMark.totalTraditional}`;
                } else if (modInfo.moduleControlMark && modInfo.moduleControlMark.totalTraditional === 0) {
                    return "mark mark0";
                } else {
                    return "mark";
                }
            },
            getModule() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`teacher/teacherModuleMarks/${this.$route.params.group}/${this.activeModule.id}`)
                    .then(res => {
                        this.moduleInfo = res;
                        this.loading = false;
                    });
            },
            moduleAdded() {
                this.addModuleDialog = false;
                this.getModule();
            },
            userIsAdminOrEdudept() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.edudept]);
            },
            userIsTeacher() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.professor]);
            },
            
            getModuleMarkStatusText(moduleMarkStatus) {
                const foundStatus = moduleMarkStatuses.find(i => i.id === moduleMarkStatus);
                
                if (foundStatus) {
                    return foundStatus.nameUk
                }
                
                return "";
            },
            getModuleMarkStatusIcon(moduleMarkStatus) {
                const foundStatus = moduleMarkStatuses.find(i => i.id === moduleMarkStatus);

                if (foundStatus) {
                    return foundStatus.icon
                }

                return "";
            },

            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getModule();
            },
            removedAll() {
                this.dialogRemoveAll = false;

                this.getModule();
            }
        }
    }
</script>

<style lang="scss">
    .register-container {
        padding: 5px;
    }

    .student-name-wrapper {
        display: block;
        position: relative;
        width: 100%;
        height: 50px;
        font-weight: 200;
        color: #ccc;
    }

    .student-name-text {
        position: absolute;
        bottom: 2px;
        left: 2px;
    }

    .header-cell {
        text-align: center;
        vertical-align: middle;
        padding: 10px 5px 10px 5px;
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        min-width: 25px;
        font-weight: 500;
    }

    .counter-header,
    .counter-cell {
        width: 20px;
        text-align: right;
        padding-left: 10px;
    }

    .counter-header {
        border-bottom: 1px solid #ccc;
    }

    table.marks-table {
        border-collapse: collapse;
        margin: 0 auto;
        background: #fff;

        & tr {
            & td {
                border: 1px solid transparent;
                border-bottom: 1px solid #ccc;

                &.student-name {
                    padding: 0 10px 0 5px;
                    min-width: 200px;
                    border-right: 1px solid #ccc;
                }

                &.cell {
                    min-height: 15px;
                    text-align: center;
                    border-right: 1px solid #ccc;
                }

                &.mark {
                    text-align: center;
                    color: white;
                    font-size: 1.4em;
                    padding: 0 0.8em;
                    cursor: pointer;
                }
            }
        }
    }
</style>