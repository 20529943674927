import Vue from 'vue';
import Router from 'vue-router';

import commonRoutes from "./routes/commonRoutes";

import authRoutes from "./routes/authRoutes";
import dashboardRoutes from "./routes/dashboardRoutes";
import adminRoutes from "./routes/adminRoutes";
import studentRoutes from "./routes/studentRoutes";
import deanRoutes from "./routes/deanRoutes";
import chiefDeptRoutes from "./routes/chiefDeptRoutes";
import moderatorRoutes from "./routes/moderatorRoutes";
import teacherRoutes from "./routes/teacherRoutes";
import reworkRoutes from "./routes/reworkRoutes";
import ratingRoutes from "./routes/ratingRoutes";
import ratingAdminRoutes from "./routes/ratingAdminRoutes";
import labstaffRoutes from "./routes/labstaffRoutes";
import feedbackRoutes from "./routes/feedbackRoutes";
import finalExamRoutes from "./routes/finalExamRoutes";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        { path: '*', redirect: '/notfound' }
    ],
});

router.addRoutes(commonRoutes(router));

router.addRoutes(authRoutes(router));
router.addRoutes(dashboardRoutes(router));
router.addRoutes(adminRoutes(router));
router.addRoutes(studentRoutes(router));
router.addRoutes(deanRoutes(router));
router.addRoutes(chiefDeptRoutes(router));
router.addRoutes(moderatorRoutes(router));
router.addRoutes(teacherRoutes(router));
router.addRoutes(reworkRoutes(router));
router.addRoutes(ratingRoutes(router));
router.addRoutes(ratingAdminRoutes(router));
router.addRoutes(labstaffRoutes(router));
router.addRoutes(feedbackRoutes(router));
router.addRoutes((finalExamRoutes(router)));

export default router;