<template>
    <v-card tile>
        <v-toolbar dark color="orange">
            <v-btn icon
                   dark
                   tabs
                   @click.native="$emit('shouldCloseAddEdit')"
                   class="ml-2"
            >
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Пропуски для {{student.surname}} {{student.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container grid-list-md style="height: 100%; overflow-x: auto;">
            <v-layout wrap>
                <v-flex xs7>
                    <v-layout wrap>
                        <v-flex xs5 class="align-center">
                            <date-picker
                                    v-model="filter.dateFrom"
                                    label="Дата заняття (від)"
                            />
                        </v-flex>
                        <v-flex xs5>
                            <date-picker
                                    v-model="filter.dateTo"
                                    label="Дата заняття (до)"
                            />
                        </v-flex>
                        <v-flex xs2 class="justify-end">
                            <div class="d-flex align-center align-content-center">
                                <v-btn :loading="loading" color="primary" @click="getItems()">Застосувати</v-btn>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                        <v-btn v-on="on" class="ml-2" icon @click="selectQuarantineInterval()">
                                            <v-icon>enhanced_encryption</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Вибрати період часу карантину COVID-19</span>
                                </v-tooltip>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs4 offset-xs1>
                    <v-layout justify-end>
                        <v-btn v-if="selected.length > 0" 
                               dark 
                               large 
                               color="red"
                               @click="giveAdmission(true)"
                        >
                            Видати {{selected.length}} допуски(-ів)
                        </v-btn>
                    </v-layout>
                </v-flex>
            </v-layout>
            
            <admission-types-legend/>
            
            <v-flex xs12>
                <v-data-table
                        :headers="headers"
                        :items="reworkItems"
                        :loading="loading"
                        v-model="selected"
                        :server-items-length="reworkItems.length"
                        class="elevation-3"
                        show-select
                        hide-default-footer
                >
                    <template v-slot:item.actions="props">
                        <v-tooltip bottom v-if="props.item.topicAbsence === null || (props.item.topicAbsence !== null && !props.item.topicAbsence.isValid)">
                            <template v-slot:activator="{ on }">
                                <v-btn @click="giveAdmission(false, props.item)" v-on="on" icon class="mx-0">
                                    <v-icon large color="primary">touch_app</v-icon>
                                </v-btn>
                            </template>
                            <span>Видати допуск</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="props.item.topicAbsence !== null && props.item.topicAbsence.isValid &&
                                                        !getMarkAdmissionValidity(props.item.topicAbsence)">
                            <template v-slot:activator="{ on }">
                                <v-btn @click="prolongateAdmission(props.item)" v-on="on" icon class="mx-0">
                                    <v-icon large color="primary">schedule</v-icon>
                                </v-btn>
                            </template>
                            <span>Продовжити допуск</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.attachment="{item}">
                        <div v-if="!isEmpty(item.attachmentFileName)" class="d-flex justify-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn v-on="on" icon @click="onGetAttachment(item)">
                                        <v-icon>visibility</v-icon>
                                    </v-btn>
                                </template>
                                <span>Переглянути додаток (довідка, квитанція)</span>
                            </v-tooltip>
                        </div>
                        
                        <div class="text-center" v-else>-</div>
                        
                        <div v-if="item.attachmentIsRejected" class="d-flex justify-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-icon color="red" v-on="on">error</v-icon>
                                </template>
                                <span>{{item.attachmentRejectionText}}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    
                    <template v-slot:item.payable="props">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-if="props.item.isSubjectPayable" color="red" v-on="on">attach_money</v-icon>
                            </template>
                            <span>Оплачувані допуски</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-if="!props.item.isSubjectPayable" color="green" v-on="on">strikethrough_s</v-icon>
                            </template>
                            <span>НЕоплачувані допуски</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.reworkPrice="props">
                        {{props.item.reworkPrice}}
                    </template>
                    <template v-slot:item.subject="props">
                        {{ props.item.subject.nameUk }}
                    </template>
                    <template v-slot:item.topic="props">
                        {{ `${props.item.topic.orderNumber}. ${props.item.topic.nameUk}` }}
                    </template>
                    <template v-slot:item.academicHours="props">
                        {{props.item.topic.academicHours}}
                    </template>
                    <template v-slot:item.passDate="props">
                        {{ formatShortDate(props.item.classDate) }}
                    </template>
                    <template v-slot:item.pass="props">
                        <admission-details v-if="props.item.topicAbsence != null" :topicAbsence="props.item.topicAbsence"/>
                    </template>
                    
                </v-data-table>
            </v-flex>
        </v-container>
        
        <v-dialog v-model="confirmAdmission" max-width="1100" scrollable>
            <admission-give-confirm
                    @shouldCloseSaveConfirm="confirmAdmission = false"
                    @saved="savedAdmission"
                    :admissions="selectedAdmissions"
            >
            </admission-give-confirm>
        </v-dialog>

        <v-dialog v-model="prolongateAdmissionDialog" max-width="500">
            <admission-prolongate-confirm
                    v-if="prolongateAdmissionDialog"
                    @shouldCloseSaveConfirm="prolongateAdmissionDialog = false"
                    @saved="savedAdmission"
                    :admission="prolongateAdmissionItem"
            >
            </admission-prolongate-confirm>
        </v-dialog>

        <v-dialog v-model="dialogUploadAttachmentView" max-width="1000px">
            <show-attachment
                    v-if="dialogUploadAttachmentView"
                    :item="dialogUploadItemView"
                    @shouldClose="dialogUploadAttachmentView = false"
                    @shouldCloseAndUpdate="updatedAdmission"
            ></show-attachment>
        </v-dialog>
        
    </v-card>
</template>
<script>
    import moment from "moment";
    import HttpService from "../../../HttpService";
    import DatePicker from "../../../components/common/DatePicker";
    import AdmissionDetails from "../../../components/common/rework-admissions/AdmissionDetails";
    import AdmissionTypesLegend from "../../common/rework-admissions/AdmissionTypesLegend";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import AdmissionGiveConfirm from "./AdmissionGiveConfirm";
    import AdmissionProlongateConfirm from "./AdmissionProlongateConfirm";
    import isEmpty from "lodash/isEmpty";
    import ShowAttachment from "../../student/academicAbsents/ShowAttachment";

    export default {
        components: {
            DatePicker,
            AdmissionDetails,
            AdmissionTypesLegend,
            AdmissionGiveConfirm,
            AdmissionProlongateConfirm,
            ShowAttachment
        },
        data: () => ({
            formatShortDate,
            loading: false,
            loadingSave: false,
            reworkItems: [],

            selected: [],
            
            selectedAdmissions: [],

            prolongateAdmissionDialog: false,
            prolongateAdmissionItem: null,

            filter: {
                dateFrom: moment(new Date()).subtract(3, "months"),
                dateTo: new Date(),
            },
            headers: [
                {text: 'Дії', value: 'actions', sortable: false},
                {text: 'Оплачуваний', value: 'payable', sortable: false},
                {text: 'Вкладення', value: 'attachment', sortable: false},
                {text: 'Ціна відпрацювання', value: 'reworkPrice', sortable: false},
                {text: 'Дисципліна', value: 'subject', sortable: false},
                {text: 'Тема', value: 'topic', sortable: false},
                {text: 'К-сть годин', value: 'academicHours', sortable: false},
                {text: 'Дата пропуску', value: 'passDate', sortable: false},
                {text: 'Допуск', value: 'pass', sortable: false},
            ],
            confirmAdmission: false,
            isEmpty,

            dialogUploadAttachmentView: false,
            dialogUploadItemView: null,
        }),
        props: {
            student: Object,
        },
        mounted() {
            this.getItems();
        },
        methods: {
            getItems() {
                this.loading = true;

                const dateFromQuery = moment(this.filter.dateFrom).format("YYYY-MM-DD");
                const dateToQuery = moment(this.filter.dateTo).format("YYYY-MM-DD");

                HttpService.getInstance()
                    .get(`reworks/getReworksDean/${this.student.id}?dateFrom=${dateFromQuery}&dateTo=${dateToQuery}`)
                    .then(resp => {
                        this.reworkItems = resp;
                        this.loading = false;
                    });
            },
            selectQuarantineInterval() {
                this.filter.dateFrom = moment("2020-03-06", "YYYY-MM-DD");
                this.filter.dateTo = new Date();
                
                this.getItems();
            },
            giveAdmission(isMultiple, item) {
                if (!isMultiple) {
                    this.selectedAdmissions = [item];
                }
                else {
                    this.selectedAdmissions = this.selected;
                }
                
                this.confirmAdmission = true;
            },
            savedAdmission() {
                this.getItems();
                this.confirmAdmission = false;
                this.prolongateAdmissionDialog = false;

                this.selected = [];
                this.selectedAdmissions = [];
            },
            updatedAdmission() {
                this.getItems();

                this.dialogUploadAttachmentView = false;
                this.dialogUploadItemView = null;
            },
            prolongateAdmission(item) {
                this.prolongateAdmissionItem = item;
                this.prolongateAdmissionDialog = true;
            },
            getMarkAdmissionValidity(topicAbsence) {
                const admissionEnd = moment(topicAbsence.admissionEnd);
                
                return admissionEnd.diff(moment(new Date()), "minutes") > 0;
            },
            onGetAttachment(item) {
                this.dialogUploadItemView = item;
                this.dialogUploadAttachmentView = true;
            }
        },
    }
</script>