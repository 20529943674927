<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12 md12>
                            <v-select
                                    :items="finalExamSpecs"
                                    v-model="finalExamSpecSel"
                                    item-text="nameUk"
                                    item-value="id"
                                    return-object
                                    label="Спеціальність"
                            ></v-select>
                            
                            <v-select
                                    :items="finalExamNames"
                                    v-model="form.finalExamName"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    label="Назва іспиту"
                            ></v-select>

                            <v-select
                                    :items="academicGroups"
                                    v-model="form.academicGroups"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    multiple
                                    label="Академічні групи"
                            ></v-select>

                            <date-picker
                                    v-model="form.date"
                                    label="Дата проведення іспиту"
                                    :error-messages="errors.date"
                            />
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import DatePicker from "../../../common/DatePicker";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
            DatePicker,
        },
        data: () => {
            return {
                form: {
                    academicGroups: [],
                    date: null,
                    finalExamName: null
                },
                errors: {
                    date: [],
                },
                loading: false,
                loadingSave: false,

                finalExamSpecSel: null,
                finalExamSpecs: [],

                academicGroups: [],
                finalExamNames: [],
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("navigation/getFinalExamSpecs")
                .then(finalExamSpecs => {
                    this.finalExamSpecs = finalExamSpecs;
                });
          
        },
        watch: {
            "finalExamSpecSel.id": function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    HttpService.getInstance()
                        .get(`academicGroup/getFinalExam/${newVal}`)
                        .then(academicGroups => {
                            this.academicGroups = academicGroups.map(a => {
                                a.name = `${a.groupNumber} група`
                                
                                return a;
                            });
                        });                      
                    
                    HttpService.getInstance()
                        .get(`finalExamAdmin/getFinalExamNames/${newVal}`)
                        .then(finalExamNames => {
                            this.finalExamNames = finalExamNames;
                        });   
                }
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("finalExamAdmin/saveFinalExamAcademicGroupDate", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>