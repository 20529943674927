var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Завантаження... Зачекайте, будь ласка","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.isApproved",fn:function(ref){
var item = ref.item;
return [(item.isApproved === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check")]):_vm._e()]}},{key:"item.surnamename",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.surname)+" "+_vm._s(item.name)+" "+_vm._s(item.patronymic)+" ")]}},{key:"item.academicPosition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.academicPosition ? item.academicPosition.nameShortUk : "")+" ")]}},{key:"item.departments",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.departments.map(function (d) { return d.nameUk; }).join(", "))+" ")]}},{key:"item.totalSum",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold subtitle-1"},[_vm._v(_vm._s(item.totalSum))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }