<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.actions="{item}">
            <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                <v-icon color="primary">edit</v-icon>
            </v-btn>
            <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                <v-icon color="red">delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Назва (укр)', value: 'nameUk'},
                {text: 'Назва (eng)', value: 'nameEn'},
                {text: 'Скорочено (укр)', value: 'nameShortUk'},
                {text: 'Скорочено (eng)', value: 'nameShortEn'},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>