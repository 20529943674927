<template>
    <v-tabs
            v-model="activeTab"
            centered
            fixed-tabs
            show-arrows
            class="tabs-wrapper"
            :height="40"
    >
        <v-tabs-slider class="tab-slider-top" dark color="orange accent-3"></v-tabs-slider>
        <v-tab
                ripple
                active-class="all-modules-active-tab"
                @click="setActiveModule(null)"
        >
           <v-icon>assignment</v-icon>
           <span class="hidden-sm-and-down" style="margin-left: 4px;">Огляд</span>
        </v-tab>
        <v-tab
                v-for="(module, index) in modules"
                :key="index"
                ripple
                active-class="module-active-tab"
                @click="setActiveModule(module)"
        >
            <span class="hidden-sm-and-down module-prefix">Модуль </span><span class="hidden-md-and-up module-prefix">М </span>{{ index + 1 }}
        </v-tab>
        <v-tabs-items v-model="activeTab" touchless>
            <v-tab-item>
                <slot name="overview-tab">Overview</slot>
            </v-tab-item>
            <v-tab-item
                    v-for="(module, index) in modules"
                    :key="index"
            >
                <slot name="markContent" :shouldShow="activeTab === index + 1" :module="module" :activeModule="activeModule">
                    Tab {{(index + 1)}}
                </slot>
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</template>
<script>
    import HttpService from "../../HttpService";

    export default {
        data: () => ({
            modules: [],
            activeModule: null,
            activeTab: null,
        }),
        mounted() {
            this.getItems();
        },
        watch: {
          "$route.params.subject": function(newSubjectId, oldSubjectId) {
              if (newSubjectId !== undefined && newSubjectId !== oldSubjectId) {
                  this.getItems();
              }
          }  
        },
        methods: {
            getItems(){
                HttpService.getInstance()
                    .get(`teacher/navigation/modules/${this.$route.params.subject}`)
                    .then(resp => {
                        this.modules = resp;
                    });
            },
            setActiveModule(module) {
                this.activeModule = module;
            }
        },
    }

</script>
<style lang="scss">
    .module-prefix {
        margin-right: 5px;
    }
    
    .tabs-wrapper {
        width: 100%;
    }

    .tabs__slider-wrapper {
        z-index: 2;
    }

    .module-active-tab {
        background: #29B6F6;
        opacity: 1 !important;
        color: white !important;
    }

    .all-modules-active-tab {
        background: #FF8F00;
        color: white !important;
        opacity: 1 !important;
    }

    .mark-type-active-tab {
        background: #2196F3;
        opacity: 1 !important;
    }

    .tab-slider-top {
        height: 4px;
    }
</style>