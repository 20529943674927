<template>
    <form @submit.prevent="submitForm" novalidate="true" ref="addEditForm">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-textarea :error-messages="errors.nameUk"
                                        v-model="form.nameUk"
                                        row-height="3"
                                        label="Тема лекції (українською)"
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs12>
                            <v-textarea :error-messages="errors.nameEn"
                                          v-model="form.nameEn"
                                        row-height="3"
                                        label="Тема лекції (english)"
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs4>
                            <v-text-field :error-messages="errors.orderNumber"
                                          v-model="form.orderNumber"
                                          v-mask="['###']"
                                          label="№ по порядку"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-select
                                    :items="professors"
                                    :error-messages="errors.professors"
                                    v-model="form.professors"
                                    item-text="nameValue"
                                    item-value="id"
                                    return-object
                                    label="Лектор (-и)"
                                    multiple
                                    chips
                            ></v-select>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-checkbox v-model="createAnother" label="Створити ще одну"></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import {isNumber} from "../../../helpers/numberHelper";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder
        },
        data: () => {
            return {
                form: {
                    nameUk: null,
                    nameEn: null,
                    professors: [],
                },
                errors: {
                    nameUk: [],
                    nameEn: [],
                    professors: [],
                },
                professors: [],
                loading: false,
                loadingSave: false,
                createAnother: false,
                isNumber,
            }
        },
        mounted() {
            HttpService.getInstance()
                .get(`teacher/navigation/departments/${this.$route.params.department}/professors`)
                .then(resp => {
                   this.professors = resp.map(prof => {
                       prof.nameValue = `${prof.surname} ${prof.name} ${prof.patronymic}`;
                        
                       return prof;
                   });
                });
            
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`lecture/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        watch: {
          "form.orderNumber": function(newOrderNumber) {
              this.form.classNumber = newOrderNumber;
          }  
        },
        methods: {
            submitForm() {
                this.loadingSave = true;

                const additionalParams = {
                    module: {id: this.$route.params.module},
                    department: {id: this.$route.params.department}
                };

                HttpService.getInstance()
                    .post("lecture", {...this.form, ...additionalParams})
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited", this.createAnother);
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>