<template>
    <v-container class="px-0" grid-list-md style="position: relative;">
		<block-loader v-if="loadingSave"/>
        <v-layout v-if="loading" align-center justify-center>
            <v-progress-circular
                    :size="150"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </v-layout>
        <v-layout v-if="!loading" row wrap>
            <v-flex xs12>
                <div style="max-width: 100%; overflow: auto">
                    <table class="marks-table elevation-3 mt-1">
                        <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>ПІП студента</th>
                            <th class="mark-input-control-cell-header" v-for="selectedTopic in selectedTopics">
                                <v-tooltip>
                                    <template v-slot:activator="{on}">
                                        <span v-on="on">{{selectedTopic.nameUk}}</span>
                                    </template>
                                    <span>{{selectedTopic.nameUk}}</span>
                                </v-tooltip>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(student, index) in students">
                            <td class="avatar-cell">
                                <v-avatar size="36px" v-if="student">
                                    <v-icon v-if="gendersEnum.male === student.gender">person</v-icon>
                                    <v-icon v-if="gendersEnum.female === student.gender">face</v-icon>
                                </v-avatar>
                            </td>
                            <td class="counter-cell input-mark">{{index + 1}}.</td>
                            <td class="student-name input-mark">{{student.surname}} {{student.name}}</td>
                            <td v-for="selectedTopic in selectedTopics" class="mark-input-control-cell">
                                <mark-input
                                        @change="onChangeMarkValue($event, student, selectedTopic)"
                                        :value="getMarkValue(student, selectedTopic).value"
                                        :id="getMarkValue(student, selectedTopic).id"
                                        :ects5="activeModule.ects5"
                                        :ects4="activeModule.ects4"
                                        :ects3="activeModule.ects3"
                                        :topic="selectedTopic"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td v-for="(selectedTopic, index) in selectedTopics">
                                <date-picker
                                        :value="getClassDateValue(selectedTopic)"
                                        @change="onChangeClassDate(selectedTopic, $event)"
                                        label="Дата проведення заняття"
                                        :max-date="new Date()"
                                ></date-picker>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td v-for="(selectedTopic, index) in selectedTopics">
                                <div class="red--text mark-topic-error">{{getTopicError(index)}}</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </v-flex>
        </v-layout>
    </v-container>

</template>

<script>
    import remove from "lodash/remove";
    import isEqual from "lodash/isEqual";
    import moment from "moment";
    
    import HttpService from "../../../HttpService";
    import MarkInput from "../../../components/common/MarkInput";
    import BlockLoader from "../../common/BlockLoader";
    import DatePicker from "../../common/DatePicker";
    import {gendersEnum} from "../../../constants/_internal/gendersEnum";

    export default {
        components: {
            MarkInput,
            BlockLoader,
            DatePicker
        },
        data: () => ({
            moment,
            students: [],
            form: {
                topicsMarkValues: [],
            },
            loading: false,
            gendersEnum,
        }),
        props: {
            selectedTopics: {
                type: Array,
                default: []
            },
            activeModule: {
                type: Object,
                default: {}
            },
            errors: {
                type: Object,
                default: {}
            },
			loadingSave: {
				type: Boolean,
				default: false
			},
            classDate: { // Only in edit mode
                type: String,  
            },
            editMode: {
                type: Boolean,
                default: false,
            }
        },
        mounted() {
            this.form.topicsMarkValues = this.populatetopicsMarkValuesWithTopics(this.form.topicsMarkValues, this.selectedTopics);

            this.loading = true;
            
            HttpService.getInstance()
                .get(`teacher/navigation/studentsByAcademicGroup/${this.$route.params.group}`)
                .then(resp => {
                    this.loading = false;
                    this.students = resp;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        watch: {
            form: {
                handler: function(newVal) {
                    this.$emit("change", newVal);
                },
                deep: true,
            },
            selectedTopics: function(newSelectedTopics, oldSelectedTopics) {
                if (!isEqual(newSelectedTopics, oldSelectedTopics)) {
                    this.form.topicsMarkValues = newSelectedTopics;
                }
            },
            classDate: function(newClassDate, oldClassDate) {
                if (newClassDate !== oldClassDate) {
                    this.form.classDate = newClassDate;
                }
            }
        },
        methods: {
            onChangeClassDate(topic, dateValue) {
                const foundedMarkTopic = this.form.topicsMarkValues.find(m => m.id === topic.id);

                if (foundedMarkTopic !== undefined) {
                    foundedMarkTopic.topicClass.classDate = dateValue;
                }
            },
            getClassDateValue(topic) {
                const foundedMarkTopic = this.form.topicsMarkValues.find(m => m.id === topic.id);

                if (foundedMarkTopic !== undefined) {
                    return foundedMarkTopic.topicClass.classDate;
                }
                
                return new Date();
            },
            onChangeMarkValue(markValue, student, topic) {
                const foundedMarkTopic = this.form.topicsMarkValues.find(m => m.id === topic.id);
                
                if (foundedMarkTopic !== undefined) {
                    remove(foundedMarkTopic.marks, (m) =>  m.student.id === student.id);
                    
                    foundedMarkTopic.marks.push({
                        id: markValue.id,
                        student: student,
                        markValue: markValue.value,
                    });
                }
            },
            getMarkValue(student, topic) {
                const foundedMarkTopic = this.form.topicsMarkValues.find(m => m.id === topic.id);

                if (foundedMarkTopic !== undefined && foundedMarkTopic.marks !== undefined) {
                    const foundedMarkValue = foundedMarkTopic.marks.find(m => m.student.id === student.id);

                    if (foundedMarkValue) {
                        return {
                            value: foundedMarkValue.markValue,
                            id: foundedMarkValue.id,
                        };
                    }
                    
                    return {value: undefined, id: undefined};
                }

                return {value: undefined, id: undefined};
            },
            getTopicError(topicIndex) {
               const topicError = this.errors[`topicsMarkValues[${topicIndex}]`];
               if (topicError) {
                   if (topicError.marks) {return topicError.marks}
                   return "";
               }
               return "";
            },
            populatetopicsMarkValuesWithTopics(topicsMarkValues, topics) {
                topicsMarkValues = topics.map(t => {
                    const topicMarkValue = {
                        id: t.id, 
                        marks: [], 
                        topicClass: {
                            id: 0,
                            classDate: new Date(),
                        }
                    };

                    this.students.forEach(student => {
                        topicMarkValue.marks.push({
                            id: student.id,
                            student: student,
                        });
                    });

                    return topicMarkValue;
                });

                return topicsMarkValues;
            },
        },
    }
</script>

<style lang="scss">
    .student-name {
        max-width: 200px;

        & .input-mark {
            padding: 0px 10px 0px 5px;
        }
    }

    .mark-btn {
        font-size: 20px !important;
    }

    .avatar-cell {
        padding: 0px 0px 0px 5px;
    }

    .counter-cell.input-mark {
        padding-left: 0px;
    }

    table.marks-table td.mark-input-control-cell {
        border-right: 1px solid #ccc;
        padding-left: 8px;
        max-width: 235px;

        &:last-child {
            border-right: none;
        }
    }

    table.marks-table th.mark-input-control-cell-header {
        padding: 0 5px;
        max-width: 235px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .mark-topic-error {
        padding: 5px 10px;
        max-width: 230px;
        min-width: 200px;
    }

</style>