<template>
    <div>
        <vue-headful
                title="eBSMU Список екзаменаторів"
        />
        <admin-teacher-list-index></admin-teacher-list-index>
    </div>
</template>

<script>
    import AdminTeacherListIndex from "../../components/finalExam/adminDashboard/AdminTeacherListIndex";

    export default {
        components: {
            AdminTeacherListIndex
        },
        data: () => ({
        }),

    }
</script>