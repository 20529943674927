<template>
    <v-card v-if="announcement && announcement.id !== undefined" class="elevation-8">
        <v-img
                v-if="announcement && announcement.titleImageUrl !== null"
                @click="$router.push({name: 'announcementSingle', params: {id: announcement.id}})"
                class="blue--text text--darken-4 cursor-pointer"
                height="200px"
                :src="`${$config.apiEndpoint}/file/get/announcement/${announcement.titleImageUrl}`"
        />
            <div class="title-wrapper">
                <div class="title">
                    <div class="headline">{{announcement[`title${$i18n.lang}`]}}</div>
                    <div class="subtitle">
                        <div>
                            {{ moment().format(localeDateFormats[$i18n.lang].short) }}
                        </div>
                        <div>
                            <div>
                                <v-chip color="primary" text-color="white">
                                    <div v-if="announcement.announcementFromType === announcementFromTypesEnum.department && 
                                            announcement.belongsToDepartment !== null">
                                        Кафедра: {{announcement.belongsToDepartment.nameUk}}
                                    </div>
                                    <div v-if="announcement.announcementFromType === announcementFromTypesEnum.deanOffice && 
                                            announcement.belongsToDean !== null">
                                        Декан: {{announcement.belongsToDean.surname}} {{announcement.belongsToDean.name}}
                                    </div>
                                    <div v-if="announcement.announcementFromType === announcementFromTypesEnum.curator && 
                                            announcement.belongsToCurator !== null">
                                        Куратор: {{announcement.belongsToCurator.surname}} {{announcement.belongsToCurator.name}}
                                    </div>
                                    <div v-if="announcement.announcementFromType === announcementFromTypesEnum.educationalDpt">
                                        Навчальний відділ
                                    </div>
                                </v-chip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <v-card-text v-if="announcement" v-html="announcement[`text${$i18n.lang}`]"></v-card-text>
    </v-card>
</template>
<script>
    import moment from "moment";
    import localeDateFormats from "../../i18n/locale-date-formats";
    import announcementFromTypes, {announcementFromTypesEnum} from "../../constants/mainDashboard/announcementFromTypes";
    import HttpService from "../../HttpService";

    export default {
        data: () => ({
            moment,
            localeDateFormats: localeDateFormats,
            announcement: {},
            loading: false,
            announcementFromTypesEnum
        }),
        mounted() {
            this.loading = true;
            
            HttpService.getInstance()
                .get(`announcement/${this.$route.params.id}`)
                .then(resp => {
                    this.announcement = resp;
                    this.loading = false;
                })
        },
        methods: {
            getAnnouncementType(announcementFromType) {
                return announcementFromTypes.find(a => a.id === announcementFromType).nameConst;
            }
        },
    }
</script>
<style scoped lang="scss">
    .title-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-end;
    }

    .title {
        display: block;
        padding: 0.5em;
        background: rgba(21, 101, 192, 0.45);
        color: white;
        width: 100%;

        & .headline {
            line-height: 26px !important;
            margin-bottom: 15px;
        }
    }

    .subtitle {
        font-size: 14px;
        font-weight: normal;
        justify-content: space-between;
        display: flex;
        align-items: baseline;

        & div {
            display: inline-flex;
        }
    }
</style>