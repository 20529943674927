<template>
    <div>
        <div class="headline">Лекційні заняття</div>
        <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :options.sync="pagination"
                class="elevation-3"
                :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
                }"
        >
            <template v-slot:item="props">
                <tr>
                    <td class="text-xs-left">{{ props.item.nameUk }}</td>
                    <td class="text-xs-left">{{ props.item.nameEn }}</td>
                    <td class="text-xs-left">
                        <v-chip v-for="professor in props.item.professors" :key="professor.id">
                            {{ professor.surname }} {{ professor.name }} {{ professor.patronymic }}
                        </v-chip>
                        <div v-if="props.item.professors.length === 0">-</div>
                    </td>
                    <td class="justify-center layout px-0">
                        <v-btn icon class="mx-0" @click="$emit('editItem', props.item.id)">
                            <v-icon color="primary">edit</v-icon>
                        </v-btn>
                        <v-btn icon class="mx-0" @click="$emit('deleteItem', props.item.id)">
                            <v-icon color="red">delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Тема лекції (укр)', value: 'nameUk'},
                {text: 'Тема лекції (eng)', value: 'nameEn'},
                {text: 'Лектор (-и)', value: '', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>