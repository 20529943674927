import DeanRootView from '../views/dean/DeanRootView';
import DeanHomeView from "../views/dean/DeanHomeView";
import DeanAdmissionGroupView from "../views/dean/DeanAdmissionGroupView";
import DeanAdmissionStudentView from "../views/dean/DeanAdmissionStudentView";

import routeGuard from "../routeGuards/routeGuard";
import EmptyView from "../views/common/EmptyView";
import RegistersView from "../views/admin/Register/RegistersView";
import RegisterRootView from "../views/admin/Register/RegisterRootView";
import DeanRegistersRootView from "../views/dean/DeanRegistersRootView";
import GroupsView from "../views/admin/Dictionaries/Students/GroupsView";
import StudentsView from "../views/admin/Dictionaries/Students/StudentsView";
import StudentsAccountsView from "../views/admin/Dictionaries/Students/StudentsAccountsView";
import MockView from "../views/admin/MockView";
import DeanSuperAdminPreSelect from "../views/dean/DeanSuperAdminPreSelect";
import routeGuardDean from "../routeGuards/routeGuardDean";
import DeanAdmissionsJournalView from "../views/dean/DeanAdmissionsJournalView";
import DeanAdmissionDocumentsStudentView from "../views/dean/DeanAdmissionDocumentsStudentView";

export default (router) => {
 return [
     {
         path: '/dean',
         component: DeanRootView,
         name: 'deanRoot',
         beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
         redirect: {name: 'deanSuperAdminPreselect'},
         children: [
             {
                 path: 'dean-sa-preselect',
                 name: "deanSuperAdminPreselect",
                 component: DeanSuperAdminPreSelect,
                 beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
             },
             {
                 path: 'in-development',
                 name: 'deanInDevelopment',
                 component: MockView,
             },
             {
                 path: '',
                 name: 'deanHome',
                 component: DeanHomeView,
                 beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
             },
             {
                 path: '/dean/register',
                 component: EmptyView,
                 children: [
                     {
                         path: '/dean/registers/:subject?',
                         name: 'deanRegistersRoot',
                         component: RegistersView,
                         props: { routeTo: "deanRegisters" }
                     }
                 ]
             },
             {
                 path: 'admissionsGroup/:faculty?/:speciality?/:year?/:group?',
                 name: 'admissionsGroup',
                 component: DeanAdmissionGroupView,
                 beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
             }, 
             {
                 path: 'admissionsStudent',
                 name: 'admissionsStudent',
                 component: DeanAdmissionStudentView,
                 beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
             },
             {
                 path: 'admissionsDocumentsStudent',
                 name: 'admissionsDocumentsStudent',
                 component: DeanAdmissionDocumentsStudentView,
                 beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
             },
             {
                 path: 'admissionsJournal',
                 name: 'admissionsJournal',
                 component: DeanAdmissionsJournalView,
                 beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
             },
             
             {
                 path: 'groups/:faculty?/:speciality?/:semester?',
                 name: 'deanGroupsGrid',
                 component: GroupsView,
                 beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
                 props: {routeTo: "deanGroupsGrid"}
             },
             {
                 path: 'students/:faculty?/:speciality?/:semester?/:group?',
                 name: 'deanStudentsGrid',
                 component: StudentsView,
                 beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
                 props: {routeTo: "deanStudentsGrid"}
             },
             {
                 path: 'studentsAccounts/:faculty?/:speciality?/:semester?',
                 name: 'deanStudentsAccountsGrid',
                 component: StudentsAccountsView,
                 beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
                 props: {routeTo: "deanStudentsAccountsGrid"}
             },
         ],
     },
     {
         path: '/dean/registers/:faculty?/:speciality?/:year?/:group?/:subject?',
         component: DeanRegistersRootView,
         name: 'deanRegistersFullRoot',
         redirect: {name: 'deanRegisters'},
         children: [
             {
                 path: '/dean/registers/:faculty?/:speciality?/:year?/:group?/:subject?',
                 name: "deanRegisters",
                 component: RegisterRootView,
                 props: { routeTo: "deanRegisters" },
                 beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
             },
         ]
     },
 ];
}