<template>
    <div style="position: relative">
        <block-loader v-if="loading"/>
        <v-container class="register-container">
            <v-layout justify-center>
                <v-flex xs12>
                    <div v-if="individualWorkInfo.classDate" class="subtitle-1 text-center">
                        <v-icon>event_available</v-icon> {{formatShortDate(individualWorkInfo.classDate)}}
                    </div>
                    <div v-if="individualWorkInfo.professor" class="subtitle-1 text-center">
                        <v-icon>how_to_reg</v-icon> 
                        {{individualWorkInfo.professor.surname}} {{individualWorkInfo.professor.name}} {{individualWorkInfo.professor.patronymic}}
                    </div>
                </v-flex>
            </v-layout>
            <v-layout justify-center>
                <v-flex xs12>
                    <table class="marks-table elevation-3 mb-6">
                        <thead>
                            <tr>
                                <th colspan="2" class="student-name corner-left-bottom">
                                    ПІП студента
                                </th>
                                <th class="header-cell">
                                    Сума балів без ІСРС
                                </th>
                                <th class="header-cell">
                                    Кількість балів ІСРС
                                </th>
                                <th class="header-cell">
                                    Сума балів з ІСРС
                                </th>
                                <th class="header-cell">
                                    Тип ІСРС
                                </th>
                                <th 
                                    v-if="(individualWorkInfo.wasAlreadyInserted && !individualWorkInfo.moduleWasAlreadyInserted) || userIsAdminOrEdudept()" 
                                    class="header-cell">
                                    Дії
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(modInfo, index) in individualWorkInfo.individualWorkStudentInfos">
                                <td class="counter-cell">{{index + 1}}. </td>
                                <td class="student-name">{{modInfo.student.surname}} {{modInfo.student.name}}</td>
                                <td class="cell">{{modInfo.marksValueCurrent ? modInfo.marksValueCurrent.toFixed(2) : ""}}</td>
                                <td class="cell">
                                    {{modInfo.individualWorkType !== null ? modInfo.individualWorkType.ectsPoints : "-"}}
                                </td>
                                <td class="cell">{{modInfo.marksValueWithIndividualCurrent}}</td>
                                <td class="cell individual-work-type">
                                    {{modInfo.individualWorkType
                                    ? `${modInfo.individualWorkType.nameUk} (${modInfo.individualWorkType.ectsPoints})`
                                    : "-"}}
                                </td>
                                
                                <td 
                                    v-if="(individualWorkInfo.wasAlreadyInserted && !individualWorkInfo.moduleWasAlreadyInserted) || 
                                          (individualWorkInfo.wasAlreadyInserted && userIsAdminOrEdudept())" 
                                    class="pl-1 pr-1"
                                > 
                                    <div class="d-flex justify-start">
                                        <v-btn @click="editItem(modInfo)" icon class="mx-0">
                                            <v-icon color="primary">edit</v-icon>
                                        </v-btn>
                                        <v-btn v-if="modInfo.individualWorkType !== null" @click="deleteItem(modInfo)" icon class="mx-0">
                                            <v-icon color="red">delete</v-icon>
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="addIndividualWorkDialog" max-width="1150px" scrollable>
            <add-individual-work-dialog
                    v-if="addIndividualWorkDialog"
                    :activeModule="activeModule"
                    @shouldSaveAndClose="moduleAdded"
                    @shouldClose="addIndividualWorkDialog = false"
            ></add-individual-work-dialog>
        </v-dialog>
        
        <v-dialog v-model="dialogRemove" max-width="900px">
            <individual-work-delete-confirm
                    v-if="dialogRemove"
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            ></individual-work-delete-confirm>
        </v-dialog>

        <v-dialog v-model="dialogRemoveAll" max-width="900px">
            <individual-work-delete-all-confirm
                v-if="dialogRemoveAll"
                :module-id="activeModule.id"
                @shouldClose="dialogRemoveAll = false"
                @removed="removedAll"
            >
            </individual-work-delete-all-confirm>
        </v-dialog>

        <v-dialog v-model="dialogEdit" max-width="1150px" scrollable>
            <add-edit-individual-work-dialog v-if="dialogEdit"
                                             :individual-work-item="editId" 
                                             :active-module="activeModule"
                                             @shouldCloseSaveConfirm="dialogEdit = false"
                                             @saved="saved"
            ></add-edit-individual-work-dialog>
        </v-dialog>

        <v-btn
                v-if="!individualWorkInfo.wasAlreadyInserted && !individualWorkInfo.moduleWasAlreadyInserted && userIsTeacher()"
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addIndividualWorkDialog = true"
        >
            <v-icon>add</v-icon>
        </v-btn>

        <v-speed-dial
                dark
                fixed
                bottom
                right
                fab
                direction="top"
                v-model="fab"
                v-if="userIsAdminOrEdudept()"
        >
            <template v-slot:activator>
                <v-btn
                        color="red darken-1"
                        dark
                        fab
                >
                    <v-icon v-if="fab">close</v-icon>
                    <v-icon v-else>add</v-icon>
                </v-btn>
            </template>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-if="!individualWorkInfo.wasAlreadyInserted && !individualWorkInfo.moduleWasAlreadyInserted"
                            v-on="on"
                            fab
                            dark
                            small
                            color="primary"
                            @click="addIndividualWorkDialog = true"
                    >
                        <v-icon>add</v-icon>
                    </v-btn>
                </template>
                <span>Додати ІСРС</span>
            </v-tooltip>

            <v-tooltip bottom v-if="userIsAdminOrEdudept()">
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            fab
                            dark
                            small
                            color="red"
                            @click="dialogRemoveAll = true"
                    >
                        <v-icon>delete</v-icon>
                    </v-btn>
                </template>
                <span>Видалити ВСІ ІСРС з даного модуля</span>
            </v-tooltip>
            
        </v-speed-dial>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import AddIndividualWorkDialog from "./AddIndividualWorkDialog";
    import BlockLoader from "../../common/BlockLoader";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import IndividualWorkDeleteConfirm from "./IndividualWorkDeleteConfirm";
    import AddEditIndividualWorkDialog from "./AddEditIndividualWorkDialog";
    import {rolesEnum} from "../../../constants/rolesEnum";
    import IndividualWorkDeleteAllConfirm from "./IndividualWorkDeleteAllConfirm";
    
    export default {
        components: {
            AddIndividualWorkDialog,
            AddEditIndividualWorkDialog,
            IndividualWorkDeleteConfirm,
            BlockLoader,
            IndividualWorkDeleteAllConfirm,
        },
        props: {
            activeModule: {
                type: Object
            }  
        },
        data() {
            return {
                fab: false,
                addIndividualWorkDialog: false,
                
                dialogRemove: false,
                deleteId: null,
                
                dialogEdit: false,
                editId: null,

                dialogRemoveAll: false,
                
                individualWorkInfo: {
                    individualWorkStudentInfos: [],
                    wasAlreadyInserted: false,
                    moduleWasAlreadyInserted: false,
                },
                loading: false,
                formatShortDate,
            }
        },
        mounted() {
            this.$ga.event('professor', 'getIndividualWorks', 'groupId', this.$route.params.group);

            this.getModule();
        },
        methods: {
            getMarkClass(modInfo) {
                if (modInfo.moduleControlMark && modInfo.moduleControlMark.totalTraditional) {
                    return `mark mark${modInfo.moduleControlMark.totalTraditional}`;
                }
                else {
                    return "mark mark0";
                }
            },
            getModule() {
                this.loading = true;
                
                HttpService.getInstance()
                    .get(`teacher/teacherIndividualWorkMarks/${this.$route.params.group}/${this.activeModule.id}`)
                    .then(res => {
                        this.individualWorkInfo = res;
                        this.loading = false;
                    });
            },
            moduleAdded() {
                this.addIndividualWorkDialog = false;
                this.getModule();
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getModule();
            },
            editItem(itemId) {
                this.dialogEdit = true;
                this.editId = itemId;
            },
            saved() {
                this.dialogEdit = false;
                this.editId = null;

                this.getModule();
            },

            userIsTeacher() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.professor]);
            },
            userIsAdminOrEdudept() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.edudept]);
            },
            removedAll() {
                this.dialogRemoveAll = false;

                this.getModule();
            }
        }
    }
</script>

<style lang="scss">
    .register-container {
        padding: 5px;
    }

    .student-name-wrapper {
        display: block;
        position: relative;
        width: 100%;
        height: 50px;
        font-weight: 200;
        color: #ccc;
    }
    .student-name-text {
        position: absolute;
        bottom: 2px;
        left: 2px;
    }

    .header-cell {
        text-align: center;
        vertical-align: middle;
        padding: 10px 5px 10px 5px;
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        min-width: 25px;
        font-weight: 500;
    }

    .counter-header,
    .counter-cell {
        width: 20px;
        text-align: right;
        padding-left: 10px;
    }

    .counter-header {
        border-bottom: 1px solid #ccc;
    }

    table.marks-table {
        border-collapse: collapse;
        margin: 0 auto;
        background: #fff;

        & tr {
            & td {
                border: 1px solid transparent;
                border-bottom: 1px solid #ccc;
                
                &.student-name {
                    padding: 5px 10px 5px 5px;
                    min-width: 200px;
                    border-right: 1px solid #ccc;
                }
                
                &.cell {
                    min-height: 15px;
                    text-align: center;
                    border-right: 1px solid #ccc;
                }
                
                &.individual-work-type {
                    padding: 5px 10px 5px 5px;
                }
            }
        }
    }
</style>