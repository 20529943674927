<template>
    <div :class="(mark.value != null) ? `mark-wrapper mark mark${mark.value}` : 'mark-wrapper mark mark-empty'">
        <div v-if="mark.editType === topicMarkEditTypesEnum.rework" class="triangle rework"></div>
        <v-menu
                v-if="mark.value != null"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="220"
                offset-x
                offset-overflow
                transition="slide-x-transition"
                class="mark-value-menu"
        >
            <template v-slot:activator="{ on }">
                <span 
                        v-on="on"
                        class="cursor-pointer mark-value"
                >
                   <mark-inner :mark="mark"></mark-inner>
                </span>
            </template>
            <v-card>
                <v-card-text style="max-width: 266px;">
                    <v-layout wrap>
                        <v-flex xs6>
                            <v-chip class="subtitle-1"
                                    :color="getMarkChipColor(mark)"
                                    text-color="white"
                            >
                                <mark-inner :mark="mark"></mark-inner>
                            </v-chip>
                        </v-flex>
                        <v-flex xs6 class="ects-points">
                            <span class="subtitle-1 font-weight-bold">ECTS: <span class="font-weight-light">{{this.ectsPoints}}</span></span>
                        </v-flex>
                    </v-layout>
                    <template v-if="loaded && !loading">
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Дата заняття: </span>
                            {{formatShortDate(markInfo.classDate)}}
                        </v-flex>
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Дата внесення: </span>
                            {{formatLongDate(markInfo.inputDate)}}
                        </v-flex>
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Дисципліна: </span>
                            {{markInfo.subject ? markInfo.subject.nameUk : ""}}
                        </v-flex>
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Тема: </span>
                            {{markInfo.topic ? markInfo.topic.nameUk : ""}}
                        </v-flex>
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Викладач: </span>
                            {{`${markInfo.professor.surname} ${markInfo.professor.name.charAt(0)}.
                            ${markInfo.professor.patronymic.charAt(0)}.`}}
                        </v-flex>
                        <v-flex class="history-block" xs12 v-if="markInfo.history !== null">
                            <span class="subtitle-1 font-weight-bold">Історія редагувань: </span>
                            <table class="table-history">
                                <tr v-for="his in markInfo.history">
                                    <td>{{his.professor.surname}} {{his.professor.name}}</td>
                                    <td>{{his.value}}</td>
                                </tr>
                            </table>
                        </v-flex>
                    </template>
                    
                    <template v-if="!loaded && loading">
                        <v-container align-center align-content-center grid-list-md fluid fill-height>
                            <v-layout row wrap justi>
                                <v-progress-circular
                                        :size="150"
                                        color="primary"
                                        indeterminate
                                ></v-progress-circular>
                            </v-layout>
                        </v-container>
                    </template>
                    
                </v-card-text>
            </v-card>
        </v-menu>
        <div v-if="mark.value === null && mark.topicClassId">
            <template v-if="userIsTeacher()">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon v-on="on">contact_support</v-icon>
                    </template>
                    <span>Зверніться до адміністратора з питання внеення оцінки</span>
                </v-tooltip>
            </template>
           <template v-if="userIsAdminOrEdudept() || userIsModeratorOrHeadOfDepartment()">
               <v-btn icon fab small @click="$emit('addSingleMark', {topicClassId: mark.topicClassId, studentId: student.id})">
                   <v-icon>add</v-icon>
               </v-btn>
           </template>
        </div>
        <div v-if="mark.value === null && mark.topicClassId === null">-</div>
    </div>
</template>
<script>
    import {getMarkChipColor} from "../../helpers/markHelper";
    import HttpService from "../../HttpService";
    import {formatShortDate, formatLongDate} from "../../helpers/dateHelper";
    import {topicMarkEditTypesEnum} from "../../constants/topicMarkEditTypes";
    import MarkInner from "./MarkInner";
    import {rolesEnum} from "../../constants/rolesEnum";

    export default {
        data: () => ({
            menu: false,
            ectsPoints: 0,
            loaded: false,
            loading: false,
            getMarkChipColor,
            markInfo: {
                
            },
            formatShortDate,
            formatLongDate,
            topicMarkEditTypesEnum
        }),
        props: {
            mark: {
                type: Object,
                default: () => {
                    return {value: null}
                }
            },
            student: {
              type: Object,  
            },
            module: {
                type: Object,
                default: () => {
                    return {
                        ects3: 0,
                        ects4: 0,
                        ects5: 0,
                    };
                }
            },
            
        },
        mounted() {
            this.getEctsValue();
        },
        updated() {
            this.getEctsValue();
        },
        watch: {
            menu: function (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.loading = true;
                    
                    HttpService.getInstance()
                        .get(`teacher/teacherMarks/getMarkInfo/${this.mark.id}`)
                        .then(resp => {
                            this.loading = false;
                            this.loaded = true;
                            this.markInfo = resp;
                        });
                }
            }
        },
        components: {
          MarkInner  
        },
        methods: {
            getEctsValue() {
                switch (this.mark.value) {
                    case 5:
                        this.ectsPoints = this.module.ects5;
                        break;
                    case 4:
                        this.ectsPoints = this.module.ects4;
                        break;
                    case 3:
                        this.ectsPoints = this.module.ects3;
                        break;
                    case 2:
                    case 0:
                    default:
                        this.ectsPoints = 0;
                        break;
                }
            },
            userIsTeacher() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.professor]);
            },
            userIsAdminOrEdudept() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.edudept]);
            },
            userIsModeratorOrHeadOfDepartment() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.headDepartment, rolesEnum.moderator, rolesEnum.admin]);
            },
        }
    }
</script>
<style lang="scss">
    .ects-points {
        display: flex;
        align-items: center;
    }
    
    .history-block {
        margin-top: 10px;
    }
    
    table.table-history {
        border-spacing: 0;
        border-collapse: separate;
        
        tr {
            td {
                padding: 5px;
                border-right: 1px solid #ccc;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                
                &:first-child {
                    border-left: 1px solid #ccc;
                }
            }
        }
    }
</style>