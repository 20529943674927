<template>
    <div>
        <vue-headful title="eBSMU"/>
        <student-krok-results></student-krok-results>
    </div>
</template>

<script>
    import StudentKrokResults from "../../components/finalExam/studentDashboard/StudentKrokResults";

    export default {
        components: {
            StudentKrokResults,
        },
        data: () => ({
        }),
        
    }
</script>