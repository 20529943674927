<template>
    <v-alert
            v-if="userIsAdminOrStudent"
            outlined
            type="warning"
            prominent
            border="left"
    >
        <h3 class="text-center">{{$t("dashboard.supportTitle")}}</h3>
        <div>{{$t("dashboard.accountQuestions")}} – <a href="tel:+380372526559">(0372) 52-65-59</a>, <a href="mailto:galya.mararash@bsmu.edu.ua">galya.mararash@bsmu.edu.ua</a> (Марараш Г.Г.)</div>
        <div>{{$t("dashboard.technicalQuestions")}} – <a href="tel:+380372573910">(0372) 57-39-10</a>, <a href="mailto:alex@bsmu.edu.ua">alex@bsmu.edu.ua</a> (Мельничук О.М.)</div>
        <v-divider class="mt-2 mb-2"></v-divider>
        <div class="body-2">{{$t("dashboard.postNote")}}</div>
    </v-alert>
</template>
<script>
    import {rolesEnum} from "../../constants/rolesEnum";

    export default {
        methods: {
            userIsAdminOrStudent() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.student]);
            },
        }
    }
</script>