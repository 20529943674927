<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="professors"
                :server-items-length="professors.length"
                :loading="loading"
                class="elevation-3"
                hide-default-footer
        >
            <template v-slot:item.professor="{item}">
                {{ item.surname }} {{item.name}} {{item.patronymic}}
            </template>

          <template v-slot:item.actions="{item}">
            <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
              <v-icon color="red">delete</v-icon>
            </v-btn>
          </template>
          
        </v-data-table>
    </div>
</template>

<script>
    
    export default {
        props: {
            professors: {
                type: Array,
            },
            loading: {
                type: Boolean,
            }
        },
        data: () => ({
            headers: [
                {text: 'ПІП декана/голови/секретаря комісії', value: 'professor'},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>
<style lang="scss">
    .exam-block {
        display: inline-block;
        border-right: 1px solid #CCCCCC;
        
        &:first-child {
            border-left: 1px solid #CCCCCC;
        }
    }
</style>