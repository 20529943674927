<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap>
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 160px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-list-item :to="{name: 'userManagementGrid'}"
                                         exact-active-class="list-title-active">
                                <v-list-item-content>
                                    <v-list-item-title>Всі ролі</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            
                            <v-subheader class="mt-4 grey--name name--darken-1">Ролі</v-subheader>
                            <navigation-list-template :items="roles"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 160px; width: calc(100% - 460px);">
                <v-card>
                    <user-management-index/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../../HttpService";
    import UserManagementIndex from "../../../components/admin/UserManagement/UserManagementIndex";
    import NavigationListTemplate from "../../../components/common/NavigationListTemplate";

    export default {
        components: {
            UserManagementIndex,
            NavigationListTemplate
        },
        mounted() {
            HttpService.getInstance()
                .get("role")
                .then(res => {
                    this.roles = res.map(role => {
                        role.route = {
                            name: 'userManagementGrid',
                            params: {
                                roleId: role.id,
                            }
                        };

                        return role;
                    });
                });
        },
        data: () => {
            return {
                roles: [],
            };
        }
    }
</script>