var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.pagination,"footer-props":{
           itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatShortDate(item.date))+" ")]}},{key:"item.scheduleItems",fn:function(ref){
        var item = ref.item;
return [_c('div',_vm._l((item.scheduleItems),function(schedule,index){return _c('v-chip',{key:index,staticClass:"mb-2 mt-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(schedule.nameUk)+" ("),_vm._l((schedule.specialities),function(spec,i){return _c('span',{key:i},[_vm._v(_vm._s(spec.nameShortUk)),_c('span')])}),_vm._v(") ")],2)}),1)]}},{key:"item.reworkStartTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatLongDate(item.reworkStartTime))+" ")]}},{key:"item.reworkEndTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatLongDate(item.reworkEndTime))+" ")]}},{key:"item.reworkUrl",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.reworkUrl))]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editItem', item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("edit")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }