var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"register-container",staticStyle:{"position":"relative"}},[(_vm.loading)?_c('block-loader'):_vm._e(),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.modulesInfo)?_c('table',{staticClass:"marks-table elevation-3 mb-6"},[_c('thead',[_c('tr',[_c('th',{staticClass:"counter-header",attrs:{"rowspan":"2"}}),_c('th',{staticClass:"student-name corner-left-top"},[_vm._v(" Модулі ")]),_vm._l((_vm.modulesInfo.moduleAndModuleClasses),function(moduleAndModuleClass,index){return _c('th',{staticClass:"cell-lesson-date",attrs:{"colspan":moduleAndModuleClass.finalControlType === _vm.finalControlTypesEnum.finalModuleControl ? 3 : 2}},[_c('div',{staticClass:"lesson-date"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"cursor-pointer"},on),[_vm._v(" Модуль №"+_vm._s(moduleAndModuleClass.orderBy)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(moduleAndModuleClass.nameUk))])])],1)])}),_c('th',{staticClass:"cell-lesson-date"},[_c('div',{staticClass:"lesson-date"},[_vm._v(" Дисципліна ")])])],2),_c('tr',[_c('th',{staticClass:"student-name corner-left-bottom"},[_vm._v(" ПІП студента ")]),_vm._l((_vm.modulesInfo.moduleAndModuleClasses),function(moduleAndModuleClass,index){return [_c('th',{staticClass:"cell-module-overview-type"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"cursor-pointer"},on),[_vm._v("П")])]}}],null,true)},[_c('span',[_vm._v("Поточні оцінки")])])],1),(moduleAndModuleClass.finalControlType === _vm.finalControlTypesEnum.finalModuleControl)?_c('th',{staticClass:"cell-module-overview-type"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"cursor-pointer"},on),[_vm._v("МК")])]}}],null,true)},[_c('span',[_vm._v("Модульний контроль")])])],1):_vm._e(),_c('th',{staticClass:"cell-module-overview-type"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"cursor-pointer"},on),[_vm._v("∑")])]}}],null,true)},[_c('span',[_vm._v("Загальна сума")])])],1)]}),_c('th',{staticClass:"cell-module-overview-type"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"cursor-pointer"},on),[_vm._v("∑")])]}}],null,false,4181763731)},[_c('span',[_vm._v("Загальна сума")])])],1)],2)]),_c('tbody',_vm._l((_vm.modulesInfo.studentsModules),function(studentsModule,index){return _c('tr',[_c('td',{staticClass:"counter-cell"},[_vm._v(_vm._s(index + 1)+".")]),_c('td',{staticClass:"student-name"},[_vm._v(" "+_vm._s(studentsModule.student.surname)+" "+_vm._s(studentsModule.student.name)+" "+_vm._s(studentsModule.student.patronymic)+" ")]),_vm._l((studentsModule.moduleControlMarks),function(moduleMark,index){return [_c('td',{staticClass:"module-overview-cell"},[_vm._v(" "+_vm._s(_vm.valueNullToDash(moduleMark.marksValueCurrent))+" ")]),(moduleMark.finalControlType === _vm.finalControlTypesEnum.finalModuleControl)?_c('td',{staticClass:"module-overview-cell"},[_vm._v(" "+_vm._s(_vm.valueNullToDash(moduleMark.moduleControlPoints))+" ")]):_vm._e(),_c('td',{staticClass:"module-overview-cell"},[_vm._v(" "+_vm._s(_vm.valueNullToDash(moduleMark.totalPoints))+" ")])]}),_c('td',{staticClass:"module-overview-cell"},[_vm._v(" "+_vm._s(_vm.getSubjectControlMark(studentsModule))+" ")])],2)}),0),_c('tfoot',[_c('tr',[_c('td'),_c('td',{staticClass:"cell-professor-name corner-prof-bottom"},[_vm._v(" ПІП викладача ")]),_vm._l((_vm.modulesInfo.moduleAndModuleClasses),function(moduleAndModuleClass,index){return [_c('td',{staticClass:"cell-professor-name",attrs:{"colspan":moduleAndModuleClass.finalControlType === _vm.finalControlTypesEnum.finalModuleControl ? 3 : 2}},[_c('marks-list-professor-cell',{attrs:{"topic-info":moduleAndModuleClass}})],1)]})],2)])]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }