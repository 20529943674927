var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.professors,"server-items-length":_vm.professors.length,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.professor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.professor.surname)+" "+_vm._s(item.professor.name)+" "+_vm._s(item.professor.patronymic)+" ")]}},{key:"item.meetLink",fn:function(ref){
var item = ref.item;
return [((item.meetLink !== '' && item.meetLink !== null))?_c('div',[_c('a',{attrs:{"href":item.meetLink,"target":"_blank"}},[_vm._v(" "+_vm._s(item.meetLink)+" ")])]):_vm._e(),((item.meetLink === '' || item.meetLink === null))?_c('div',[_c('i',[_vm._v("Немає посилання")])]):_vm._e()]}},{key:"item.finalExamNames",fn:function(ref){
var item = ref.item;
return _vm._l((item.finalExamNames),function(finalExamName,index){return _c('v-chip',{key:index},[_c('div',[_vm._v(_vm._s(finalExamName.name)+" ("+_vm._s(finalExamName.speciality.nameShortUk)+")")])])})}},{key:"item.student",fn:function(ref){
var item = ref.item;
return [(item.student !== null)?_c('div',[_vm._v(" "+_vm._s(item.student.surname)+" "+_vm._s(item.student.name)+" "+_vm._s(item.student.patronymic)+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }