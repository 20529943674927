<template>
    <div>
        <vue-headful
                title="eBSMU Протоколи випускного іспиту"
        />
        <final-exam-protocols-index></final-exam-protocols-index>
    </div>
</template>

<script>
    import FinalExamProtocolsIndex from "../../components/finalExam/adminDashboard/protocols/FinalExamProtocolsIndex";
    
    export default {
        components: {
            FinalExamProtocolsIndex
        },
        data: () => ({
        }),
    }
</script>