var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.pagination,"footer-props":{
           itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.nameUk",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.faculty.nameUk))]}},{key:"item.deans",fn:function(ref){
        var item = ref.item;
return [_vm._l((item.deans),function(dean){return _c('v-chip',{key:dean.id},[_vm._v(" "+_vm._s(dean.surname)+" "+_vm._s(dean.name)+" "+_vm._s(dean.patronymic)+" ")])}),(item.deans.length === 0)?_c('div',[_vm._v("-")]):_vm._e()]}},{key:"item.inspectors",fn:function(ref){
        var item = ref.item;
return [_vm._l((item.inspectors),function(inspector){return _c('v-chip',{key:inspector.id},[_vm._v(" "+_vm._s(inspector.surname)+" "+_vm._s(inspector.name)+" "+_vm._s(inspector.patronymic)+" ")])}),(item.inspectors.length === 0)?_c('div',[_vm._v("-")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editItem', item.faculty.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("edit")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }