var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-progress-circular',{attrs:{"size":150,"color":"primary","indeterminate":""}})],1)],1):_vm._e(),(!_vm.loading)?_c('v-list',{attrs:{"two-line":""}},[_vm._l((_vm.topics),function(topic,index){return [_c('v-list-item',{key:topic.id,class:{
                                'active-list-item': _vm.isSelectedTopic(topic), 
                                'passed-list-item': (topic.topicClass !== null),
                                'inactive-list-item': _vm.isInactiveTopic(topic),
                            },attrs:{"ripple":topic.topicClass === null && !_vm.isInactiveTopic(topic)},on:{"click":function($event){(topic.topicClass === null) 
                                    ? _vm.toggleTopic(topic) 
                                    : null}}},[_c('v-list-item-content',{staticClass:"topic-number"},[_c('span',{staticClass:"title"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("turned_in")])]}}],null,true)},[_c('span',[_vm._v("Номер теми по порядку")])]),_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(topic.orderNumber))])],1),_c('span',{staticClass:"title"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [(topic.evaluationMode === _vm.topicEvaluationModeEnum.isEvaluated)?_c('v-icon',_vm._g({},on),[_vm._v("turned_in_not")]):_vm._e(),(topic.evaluationMode === _vm.topicEvaluationModeEnum.isNotEvaluated)?_c('v-icon',_vm._g({staticClass:"yellow lighten-4"},on),[_vm._v("turned_in_not")]):_vm._e()]}}],null,true)},[(topic.evaluationMode === _vm.topicEvaluationModeEnum.isEvaluated)?_c('span',[_vm._v("Номер заняття")]):_vm._e(),(topic.evaluationMode === _vm.topicEvaluationModeEnum.isNotEvaluated)?_c('span',[_vm._v("Номер неоцінювального заняття")]):_vm._e()]),_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(topic.classNumber))])],1),(topic.topicClass !== null)?_c('span',{staticClass:"caption"},[_c('v-tooltip',{attrs:{"slot":"activator","right":""},slot:"activator",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("event")])]}}],null,true)},[_c('span',[_vm._v("Дата проведення")])]),_c('span',[_vm._v(_vm._s(_vm.formatDate(topic.topicClass.classDate)))])],1):_vm._e()]),_c('v-list-item-content',[_vm._v(" "+_vm._s(topic.nameUk)+" ")]),_c('v-list-item-action',[_c('v-list-item-action-text',[_c('div',{staticClass:"subtitle-1 academic-hours"},[_vm._v(_vm._s(topic.academicHours)+" год.")])]),(topic.topicClass === null && !_vm.isSelectedTopic(topic))?_c('v-icon',{attrs:{"large":"","color":"grey lighten-1"}},[_vm._v("radio_button_unchecked")]):_vm._e(),(topic.topicClass === null && _vm.isSelectedTopic(topic))?_c('v-icon',{attrs:{"large":"","color":"blue darken-4"}},[_vm._v("radio_button_checked")]):_vm._e(),(topic.topicClass !== null)?_c('v-icon',{attrs:{"large":"","color":"orange darken-3"}},[_vm._v("done_all")]):_vm._e()],1)],1),(index + 1 < _vm.topics.length)?_c('v-divider',{key:("divider" + (topic.id))}):_vm._e()]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }