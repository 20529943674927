<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :server-items-length="total"
            :options.sync="pagination"
            class="elevation-3"
    >
        <template v-slot:item.dean="{item}">
            <div style="min-width: 100px;">
                {{item.topicAbsence.dean.surname}} {{item.topicAbsence.dean.name.charAt(0)}}. {{item.topicAbsence.dean.patronymic.charAt(0)}}.
            </div>
        </template>

        <template v-slot:item.student="{item}">
            <div style="min-width: 100px;">
                {{item.student.surname}} {{item.student.name.charAt(0)}}. {{item.student.patronymic.charAt(0)}}.
            </div>
        </template>
        
        <template v-slot:item.studentInfo="{item}">
            <div style="min-width: 100px;">
                <div>{{item.student.speciality.nameShortUk}}</div>
                <div>{{item.student.semester.yearNumber}} курс</div>
                <div>{{item.student.academicGroup.groupNumber}} група</div>
            </div>
        </template>
        
        <template v-slot:item.payable="props">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-if="props.item.isSubjectPayable" color="red" v-on="on">attach_money</v-icon>
                </template>
                <span>Оплачувані допуски</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-if="!props.item.isSubjectPayable" color="green" v-on="on">strikethrough_s</v-icon>
                </template>
                <span>НЕоплачувані допуски</span>
            </v-tooltip>
        </template>
        <template v-slot:item.reworkPrice="props">
            {{props.item.reworkPrice}}
        </template>
        <template v-slot:item.subject="props">
            {{ props.item.subject.nameUk }}
        </template>
        <template v-slot:item.topic="props">
            {{ `${props.item.topic.orderNumber}. ${props.item.topic.nameUk}` }}
        </template>
        <template v-slot:item.academicHours="props">
            {{props.item.topic.academicHours}}
        </template>
        <template v-slot:item.passDate="props">
            {{ formatShortDate(props.item.classDate) }}
        </template>
        <template v-slot:item.emitDate="props">
            {{ props.item.topicAbsence != null ? formatLongDate(props.item.topicAbsence.admissionTime) : "-" }}
        </template>
        <template v-slot:item.number="{item}">
            {{ item.topicAbsence.number }}
        </template>
        <template v-slot:item.pass="props">
            <admission-details v-if="props.item.topicAbsence != null" :topicAbsence="props.item.topicAbsence"/>
        </template>

    </v-data-table>
</template>
<script>
    import AdmissionDetails from "../../common/rework-admissions/AdmissionDetails";
    import {formatLongDate, formatShortDate} from "../../../helpers/dateHelper";

    export default {
        components: {
          AdmissionDetails,  
        },
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        data: () => ({
            formatShortDate,
            formatLongDate,
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Виданий ким', value: 'dean', sortable: false, },
                {text: 'Виданий кому', value: 'student', sortable: false,},
                {text: 'Інформація про студента', value: 'studentInfo', sortable: false,},
                {text: 'Оплачуваний', value: 'payable', sortable: false},
                {text: 'Ціна відпрацювання', value: 'reworkPrice', sortable: false},
                {text: 'Дисципліна', value: 'subject', sortable: false},
                {text: 'Тема', value: 'topic', sortable: false},
                {text: 'К-сть годин', value: 'academicHours', sortable: false},
                {text: 'Дата пропуску', value: 'passDate', sortable: false},
                {text: 'Дата видачі допуску', value: 'emitDate', sortable: false},
                {text: 'Номер допуску', value: 'number', sortable: false},
                {text: 'Допуск', value: 'pass', sortable: false},
            ],
        }),
    }
</script>