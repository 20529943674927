<template>
    <v-app>
        <vue-headful title="eBSMU"/>
        <toolbar color="blue darken-2"/>
        <v-main>
          <router-view/>
<!--            <router-view v-if="!isWebView()"/>-->
<!--            <div class="headline d-flex justify-center align-center mt-12" v-if="isWebView()">-->
<!--                Your browser is not supported!-->
<!--            </div>-->
        </v-main>
        <v-footer
                color="grey lighten-3"
                app
        >
            <v-layout xs12 wrap justify-space-between>
                <v-flex xs3 md6 class="left-footer-block">
                    <v-layout wrap>
                        <v-flex xs12 md2 class="caption">&copy;&nbsp;{{ new Date().getFullYear() }}</v-flex>
                        <v-flex xs12 md2 class="caption">v{{version}} | {{build}}</v-flex>
                        <v-flex xs12 md2 class="caption">API {{apiVersion}} | {{apiBuild}}</v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs9 md6 class="text-right">
                    <v-layout wrap justify-space-between>
                        <v-flex xs12 md4 class="caption px-1">Architecture & Development: Adrian&nbsp;Barbe</v-flex>
                        <v-flex xs12 md4 class="caption px-1">DevOps: Alex&nbsp;Melnichuk</v-flex>
                        <v-flex xs12 md4 class="caption px-1">Consulting & Legal: Volodymyr&nbsp;Khodorovskyi</v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-footer>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/dashboard/common/Toolbar";
    // import isWebview from "is-ua-webview";
    import axios from "axios";
    import HttpService from "../../HttpService";

    export default {
        data: () => ({
            // isWebview,
            version: "",
            build: "",
            
            apiVersion: "",
            apiBuild: ""
        }),
        methods: {
          // isWebView() {
          //     const navOs = navigator.userAgent;
          //    
          //     return isWebview(navOs);
          // }  
        },
        components: {
            toolbar: Toolbar,
        },
        mounted() {
            axios
                .get('/version.json')
                .then(resp => {
                    if (resp) {
                        this.version = resp.data.version;
                        this.build = resp.data.build;
                    }
                });

            HttpService.getInstance()
                .get('appInfo/version')
                .then(resp => {
                    this.apiVersion = resp.version;
                    this.apiBuild = resp.build;
                });
        }
    }
</script>
<style lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .left-footer-block {

        @media #{map-get($display-breakpoints, 'md-and-down')} {
           border-right: 1px solid #ccc;
        }
    }
</style>
