var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.pagination,"footer-props":{
           itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.approved",fn:function(ref){
        var item = ref.item;
return [(!item.approved)?_c('v-icon',[_vm._v("radio_button_unchecked")]):_vm._e(),(item.approved)?_c('v-icon',[_vm._v("radio_button_checked")]):_vm._e()]}},{key:"item.published",fn:function(ref){
        var item = ref.item;
return [(!item.published)?_c('v-icon',[_vm._v("radio_button_unchecked")]):_vm._e(),(item.published)?_c('v-icon',[_vm._v("radio_button_checked")]):_vm._e()]}},{key:"item.publishStartTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatLongDate(item.publishStartTime))+" ")]}},{key:"item.publishEndTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatLongDate(item.publishEndTime))+" ")]}},{key:"item.announcementFrom",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAnnouncementFromType(item))+" ")]}},{key:"item.addressed",fn:function(ref){
        var item = ref.item;
return [(item.role)?_c('div',[_vm._v("Роль: "+_vm._s(item.role))]):_vm._e(),(item.department)?_c('div',[_vm._v("Кафедра: "+_vm._s(item.department.nameUk))]):_vm._e(),(item.faculty)?_c('div',[_vm._v("Факультет: "+_vm._s(item.faculty.nameUk))]):_vm._e(),(item.speciality)?_c('div',[_vm._v("Спеціальність: "+_vm._s(item.speciality.nameUk))]):_vm._e(),(item.semester)?_c('div',[_vm._v("Курс (семестр): "+_vm._s(item.semester.yearNumber)+" курс ("+_vm._s(item.semester.semesterNumber)+" семестр)")]):_vm._e(),(item.academicGroup)?_c('div',[_vm._v("Група: "+_vm._s(item.academicGroup.groupNumber))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"width":"120px"}},[_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('copyItem', item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("filter_none")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editItem', item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deleteItem', item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }