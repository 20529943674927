<template>
    <v-card>
        <v-card-title class="title">Внесення оцінки за заняття</v-card-title>

        <v-card-text>
            <v-layout class="mb-2" align-center wrap>
                <v-flex xs6 class="text-center font-weight-medium" style="border-right: 1px solid #ccc">
                    <template v-if="topicClassMarkSingleInput.student !== null">
                        <div>
                            <v-avatar class="mb-1" size="80px">
                                <v-icon v-if="gendersEnum.male === topicClassMarkSingleInput.student.gender">person
                                </v-icon>
                                <v-icon v-if="gendersEnum.female === topicClassMarkSingleInput.student.gender">face
                                </v-icon>
                            </v-avatar>
                        </div>
                        <div class="title">{{`${topicClassMarkSingleInput.student.surname}
                            ${topicClassMarkSingleInput.student.name} ${topicClassMarkSingleInput.student.patronymic}`}}
                        </div>
                        <div class="subtitle-1">{{topicClassMarkSingleInput.student.faculty.nameUk}}</div>
                        <div class="subtitle-1">{{topicClassMarkSingleInput.student.speciality.nameUk}}</div>
                        <div class="subtitle-1">{{topicClassMarkSingleInput.student.semester.yearNumber}} курс</div>
                        <div class="subtitle-1">{{topicClassMarkSingleInput.student.academicGroup.groupNumber}} група
                        </div>
                    </template>
                </v-flex>
                <v-flex xs6 class="text-center">
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Тема заняття: </span>
                        {{topicClassMarkSingleInput.topic.nameUk}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Тривалість заняття: </span>
                        {{topicClassMarkSingleInput.topic.academicHours}}
                        год.
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Дата заняття: </span>
                        {{formatShortDate(topicClassMarkSingleInput.topicClass.classDate)}}
                    </div>

                    <mark-input
                            class="mt-4"
                            style="margin: 0 auto;"
                            @change="topicClassMarkSingleInput.markValueObj = $event"
                            :value="topicClassMarkSingleInput.markValueObj ? topicClassMarkSingleInput.markValueObj.value : null"
                            :ects5="activeModule.ects5"
                            :ects4="activeModule.ects4"
                            :ects3="activeModule.ects3"
                            :is-absence="true"
                            :topic="topicClassMarkSingleInput.topic"
                    />
                    <div v-if="errors.markValue != null"
                         class="red--text caption"
                    >{{errors.markValue}}
                    </div>
                    
                    <div class="mt-6 gray--text text--lighten-4">
                        При індивідуальному внесенні оцінки для заняття, яке вже було проведено у вказаній академічній групі
                        датою внесення оцінки та ПІП викладача буде вважатися дата та ПІП викладача, які вказані для заняття взагалом у академічній групі.
                    </div>
                    
                </v-flex>
            </v-layout>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldClose')"
                    :disabled="loading"
            >Відміна
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="saveSingleMark"
                    :loading="loading"
                    :disabled="loading"
            >Зберегти
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../HttpService";
    import {formatShortDate} from "../../helpers/dateHelper";
    import MarkUnsatisfactoryIcons from "../common/rework-admissions/MarkUnsatisfactoryIcons";
    import MarkInput from "../../components/common/MarkInput";
    import {remapErrors} from "../../helpers/errorsHelper";
    import {gendersEnum} from "../../constants/_internal/gendersEnum";

    export default {
        components: {
            MarkUnsatisfactoryIcons,
            MarkInput
        },
        props: {
            studentId: Number,
            topicClassId: Number,
            activeModule: {
                type: Object,
            }
        },
        data: () => {
            return {
                loading: false,
                formatShortDate,
                newMarkValue: {
                    id: null,
                    value: null,
                },
                errors: {
                    markValue: null,
                },
                gendersEnum,

                topicClassMarkSingleInput: {
                    student: null,
                    markValue: null,
                    markValueObj: null,
                    topic: null,
                },
            };
        },
        mounted() {
            HttpService.getInstance()
                .get(`teacher/teacherMarks/getTopicClassMarkSingleInput/${this.topicClassId}/${this.studentId}`)
                .then(res => {
                    const topicClassMarkSingleInputMod = res;
                    topicClassMarkSingleInputMod.markValueObj = {value: topicClassMarkSingleInputMod.markValue};

                    this.topicClassMarkSingleInput = topicClassMarkSingleInputMod;
                })
        },
        methods: {
            saveSingleMark() {
                this.loading = true;

                const topicClassMarkSingleInputClonned = {
                    ...this.topicClassMarkSingleInput,
                    markValue: this.topicClassMarkSingleInput.markValueObj ? this.topicClassMarkSingleInput.markValueObj.value : null,
                };

                HttpService.getInstance()
                    .post(`teacher/teacherMarks/saveTopicClassMarkSingleInput`, topicClassMarkSingleInputClonned)
                    .then(resp => {
                        this.loading = false;

                        this.$emit("saved");
                    })
                    .catch(err => {
                        this.loading = false;

                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            }
        }
    }
</script>