<template>
    <v-app>
        <vue-headful title="eBSMU"/>
        <toolbar
                :is-lang-switcher="false"
                color="cyan darken-3"
                @toggleDrawer="drawerApp = !drawerApp"
        />

        <navigation v-if="$route.name !== 'ratingSuperAdminPreselect'" @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>

        <v-main>
            <router-view/>
        </v-main>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/common/Toolbar";
    import SnackDisplayer from "../../components/common/SnackDisplayer";
    import RatingNavigationDrawer from "../../components/rating/common/RatingNavigationDrawer";

    export default {
        components: {
            Toolbar,
            navigation: RatingNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
            drawerTeacherNav: false,
        }),
    }
</script>
