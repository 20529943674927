export default {
    message: {
        hello: 'привіт світ'
    },
    announcements: {
        title: "Оголошення",
        norecords: "Немає актуальних оголошень на даний момент...",
        types: {
            educationalDpt: "Навчальний відділ",
            deanOffice: "Деканат",
            department: "Кафедра",
            curator: "Куратор",
        }
    },
    dashboard: {
        "loginButtonTextFull": "Увійти в Електронний журнал",
        "loginButtonTextShort": "Увійти",
        "testVersionExclamation": "Це тестова версія! Вхід для студентів тільки через <a href=\"https://eznew.bsmu.edu.ua\">eznew.bsmu.edu.ua</a>!!!",
        "supportTitle": "Контакти служби підтримки Еж",
        "accountQuestions": "З питань відновлення паролю та аккаунту, неточностей в аккаунті",
        "technicalQuestions": "З технічних питань",
        "postNote": "УВАГА! Немає потреби контактувати з іншими відповідальними особами за роботу Еж. " +
        "При необхідності, Ваше запитання/проблему буде автоматично перенаправлено до інших спеціалістів!",
        "cannotLogIn": "Не вдається увійти?",
    },
    surveys: {
        title: "Опитування",
        norecords: "Немає актуальних опитувань на даний момент...",
    },
    toolbar: {
        announcements: "Оголошення",
        surveys: "Опитування",
        logOut: "Вийти",
        settings: "Налаштування"
    },
    navigation: {
        student: {
            title: "eBSMU Студент",
            description: "Всі ваші оцінки в БДМУ"
        },
        admin: {
            title: "eBSMU Адмін",
            description: "Панель адміністратора"
        },
        dean: {
            title: "eBSMU Декан",
            description: "Панель декана"
        },
        chiefdept: {
            title: "eBSMU Зав.кафедрою",
            description: "Панель завідуючого кафедрою"
        },
        moderator: {
            title: "eBSMU Модератор",
            description: "Панель модератора"
        },
        teacher: {
            title: "eBSMU Викладач",
            description: "Панель викладача"
        },
        rating: {
            title: "eBSMU Рейтинг",
            description: "Панель рейтингу"
        },
        ratingAdmin: {
            title: "eBSMU Рейтинг:Адмін",
            description: "Панель адміністратора рейтингу"
        },
        reworks: {
            title: "Відпрацювання",
            description: "Панель відпрацювань"
        },
        labstaff: {
            title: "eBSMU Лаборант",
            description: "Панель лаборанта"
        },
        teacherFeedback: {
            title: "eBSMU Feedback",
            description: "Панель зворотнього зв'язку"
        },
        finalExam: {
            title: "Випускний іспит",
            description: "Панель випускного іспиту",
        },
    },

    finalExam: {
        infoAbout: "Інформація про випускні іспити",
        notYetRegistered: "Ви ще не зареєстровані на іспит",
        registerForExam: "Зареєструватися на іспит",
        youAreRegistered: "Ви зареєструвалися на іспит",
        examName: "Назва іспиту",
        googleMeetLinkExam: "Посилання на Google Meet для складання іспиту",
        linkShow10MinsBeforeExam: "Посилання буде вказане за 10хв до початку іспиту",
        navigateToExam: "Перейти до іспиту",
        noMeetLinkAvailable: "Немає посилання на GoogleMeet",
        dateAndTimeExam: "Дата та час початку іспиту",
        dateAndTimeExamTimezone: "Київський час (GMT+3)",
        examinationPaperNumber: "Номер білету",
        ticketNumberWillBeShown10Mins: "Номер білету буде вказаний за 10хв до початку іспиту",
        passed: "Пройдено",
        notPassed: "Не пройдено",
        resultGraduationExams: "Результати складання випускних іспитів",
        noGraduationExamResults: "Немає результатів складання іспиту",
        examResultTraditional: "Результат складання (традиційна оцінка)",
        noPublishedResult: "Немає оголошеного результату",
        examResultEcts: "Результат складання (ECTS)",
        menuFinalExamStudentDashboard: "Випускні іспити",
        menuFinalExamResults: "Результати іспитів",
        chooseMenuItemOnLeft: "Виберіть пункт меню ліворуч",
        stStatus: "Статус",
        resultKrokExam: "Результат складання КРОК"
    }
}