var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.workTypeIsEnglish)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-flex flex-column"},on),[_c('img',{attrs:{"width":"20","src":_vm.enGb}})]),_c('div',_vm._g({staticClass:"d-flex flex-column ml-2 points-preview-block"},on),[_vm._v(_vm._s((_vm.workTypePoints * _vm.ratingSettings.englishRatio).toFixed(2))+" ")])]}}],null,false,1099119261)},[_c('span',[_vm._v("Англомовна позиція (x"+_vm._s(_vm.ratingSettings.englishRatio)+")")])])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-flex flex-column ml-4"},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("exit_to_app")])],1),_c('div',_vm._g({staticClass:"d-flex flex-column ml-2 points-preview-block"},on),[_vm._v(_vm._s((_vm.workTypePoints * _vm.ratingSettings.englishRatio * _vm.ratingSettings.externalWorkRatio).toFixed(2))+" ")])]}}],null,false,3183524497)},[_c('span',[_vm._v("Англомовна позиція, x"+_vm._s(_vm.ratingSettings.englishRatio)+"x"+_vm._s(_vm.ratingSettings.externalWorkRatio)+" (основний автор на іншій кафедрі)")])])],1)]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-flex flex-column"},on),[_c('img',{attrs:{"width":"20","src":_vm.ukUa}})]),_c('div',_vm._g({staticClass:"d-flex flex-column ml-2 points-preview-block"},on),[_vm._v(" "+_vm._s(_vm.workTypePoints * _vm.ratingSettings.ukrainianRatio)+" ")])]}}])},[_c('span',[_vm._v("Україномовна позиція (x"+_vm._s(_vm.ratingSettings.ukrainianRatio)+")")])])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-flex flex-column ml-4"},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("exit_to_app")])],1),_c('div',_vm._g({staticClass:"d-flex flex-column ml-2 points-preview-block"},on),[_vm._v(" "+_vm._s((_vm.workTypePoints * _vm.ratingSettings.ukrainianRatio * _vm.ratingSettings.externalWorkRatio).toFixed())+" ")])]}}])},[_c('span',[_vm._v("Україномовна позиція, x"+_vm._s(_vm.ratingSettings.ukrainianRatio)+"x"+_vm._s(_vm.ratingSettings.externalWorkRatio)+" (основний автор на іншій кафедрі)")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }