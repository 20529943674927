<template>
    <v-app>
        <vue-headful title="eBSMU"/>

        <toolbar @toggleDrawer="drawerApp = !drawerApp"/>

        <navigation v-if="$route.name !== 'moderatorSuperAdminPreselect'" @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>

        <v-main>
            <router-view/>
        </v-main>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/moderator/common/Toolbar";
    import ModeratorNavigationDrawer from "../../components/moderator/common/ModeratorNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            Toolbar,
            navigation: ModeratorNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
            drawerTeacherNav: false,
        }),
    }
</script>
