<template>
    <v-app>
        <vue-headful title="eBSMU"/>
        <navigation @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>
        <toolbar
                color="blue darken-2"
                @toggleDrawer="drawerApp = !drawerApp"
        />
        <v-main>
            <router-view/>
        </v-main>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/dashboard/common/Toolbar";
    import NavigationDrawer from "../../components/dashboard/common/NavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            toolbar: Toolbar,
            navigation: NavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
